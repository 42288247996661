import { Component } from '@angular/core';
import { TopService } from '../../services/top.service';
import { NgIf } from '@angular/common';
import { environment } from './../../../../environments/environment';

@Component({
    selector: 'app-pricelist-loader',
    templateUrl: './pricelist-loader.component.html',
    styleUrls: ['./pricelist-loader.component.scss'],
    standalone: true,
    imports: [NgIf]
})

export class PricelistLoaderComponent {
  assets = {};
  imageBaseUrl = environment.imageBaseUrl;
  get iconUrl() {
    const assetsData = this.topService.currentListingMeta?.assets;
    
      if (this.topService.isMobile) {
        this.assets = assetsData?.filter(x => x.contentType === 'mobile' 
        || x.contentType === 'Icon');
      } else {
        this.assets = assetsData?.filter(x => x.contentType === 'desktop'
        || x.contentType === 'Icon');
      }

      const currentListingMetaUrl = assetsData ? this.imageBaseUrl + this.assets[0].url :
      '../../../assets/images/icons/header/price-list-black.svg';

      return currentListingMetaUrl;
  }

  isVector = this.iconUrl.endsWith(".svg");
  
  constructor(
  	public topService: TopService
  	) { }

    ngOnInit(): void {}   
}
