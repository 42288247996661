import { environment } from './../../../../environments/environment';
import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit, OnDestroy, ViewChild, ElementRef, ViewChildren, QueryList, ChangeDetectionStrategy } from '@angular/core';
import { IVendorItemInfo } from '../../interface/IVendorItemInfo';
import { UtilityService } from '../../../core/utility.service';
import { VendorService } from '../../vendor.service';
import { IVendorStaticInfo } from '../../interface/IVendorStaticInfo';
import { IVendorDropDownData } from '../../interface/IvendorDropDownData';
import { ItemService } from '../item.service';
import * as _ from 'lodash';
import { FormGroup, UntypedFormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IVendorItemForm } from '../../interface/IVendorItemForm';
import { IPrice } from '../../../buyer/interfaces/model';
import { IVendorItemRequest } from '../../interface/IVendorItemRequest';
import { ListingSkuStatusValuesEnum, ListingSkuStatusNamesEnum } from '../../enums/listing-sku-status';
import { ItemErrorCodesEnum } from '../../enums/item-error-codes';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { CustomCurrencyPipe } from '../../../shared/pipes/currency.pipe';
import { ErrorModalComponent } from '../../../shared/ui-components/error-modal/error-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { OnlyDigitsDirective } from '../../../shared/directives/only-digits.directive';
import { DropdownComponent } from '../../../shared/dropdown/dropdown.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';


@Component({
    selector: 'app-item-simple',
    templateUrl: './item-simple.component.html',
    styleUrls: ['./item-simple.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, TooltipModule, DropdownComponent, OnlyDigitsDirective, NgxMaskModule, NgFor, NgClass, ErrorModalComponent, DatePipe, CustomCurrencyPipe]
})
export class ItemSimpleComponent implements OnInit, AfterViewInit, OnDestroy {
  private _item: IVendorItemInfo;
  private _fg: FormGroup;
  imageBaseUrl = environment.imageBaseUrl;

  @Input() set item(i: IVendorItemInfo) {
    if (i) {
      this._item = this.itemService.mapItem(i);
    }
  }

  get item() {
    return this._item;
  }

  @Input() set fg(newFg: FormGroup) {
    this.valueChangesSubscription.unsubscribe();
    if (newFg) {
      this._fg = newFg;
    }
  }

  get fg() {
    return this._fg;
  }

  customPatterns = {
    '1': { pattern: new RegExp('\[1-9\]') },
    '0': { pattern: new RegExp('[0-9]')}
  };

  @ViewChildren('maskElem') maskElements: QueryList<ElementRef>;
  @Input() dropDowns: IVendorStaticInfo;
  @Input() isAll: boolean;
  @Input() isPopup = false;
  @Input() fromItemSimple = false;
  @Output() editDetailedMode = new EventEmitter<IVendorItemInfo>();
  @Output() singleItemUpdated = new EventEmitter<IVendorItemInfo>();
  @Output() itemChanged = new EventEmitter<IVendorItemInfo>();
  @Output() updateReactivationCount = new EventEmitter();
  businessStatusDropdown: IVendorDropDownData[] = [];
  loader = false;
  updatedItem: IVendorItemInfo;
  private _internalStatuses = [];
  formPrices: IPrice[];
  itemRequest: IVendorItemRequest;
  errorCodesEnum = ItemErrorCodesEnum;
  listingSkuStatusValuesEnum = ListingSkuStatusValuesEnum;
  listingSkuStatusNamesEnum = ListingSkuStatusNamesEnum;
  tooltipMsg = 'Archived';
  valueChangesSubscription: Subscription = new Subscription();
  sellerName: string = '';
  vmItem: boolean = false;
  dqErr = false;
  dqErrMessage: string;
  showDqWarningPopUp = false;
  errorPopupTitle: string;
  reduceTooltipShow = true;

  constructor(
    protected utilityService: UtilityService,
    public vendorService: VendorService,
    public itemService: ItemService) { }

  set internalStatuses(val: any[]) {
    if (val.length === 1) {
      this.fg.controls['status_skuStatus'].disable({ emitEvent: false });
    } else {
      this.fg.controls['status_skuStatus'].enable({ emitEvent: false });
    }
    this._internalStatuses = val;
  }

  get internalStatuses() {
    return this._internalStatuses;
  }

  get pricesControlsArray(): FormGroup[] {
    return (<UntypedFormArray>this.fg.controls.prices).controls as FormGroup[];
  }

  get showBackendError() {
    return this.item.errors && this.item.errors.errors && this.item.showError;
  }

  ngOnInit() {
    this.valueChangesSubscription = new Subscription();
    this.itemSetUp();
    this.internalStatuses = (!this.isStatusInCurrentArr()) ?
      Object.assign([], this.dropDowns.skuStatus.filter(i => i.text === this.item.status.skuStatus))
      //Must start with clean deep copy OnInit. Otherwise "dropdownOptionDisabled" will corrupt itemService.statusesByRole
      : JSON.parse(JSON.stringify(this.itemService.statusesByRole));

    this.handleInternalStatuses();
    this.formPrices = JSON.parse(JSON.stringify(this.fg.getRawValue().prices));
    this.item.comments = this.item.comments.length ? this.item.comments : [{ caption: 'Special Notes', comment: '' }];
    this.disableControls();
    this.subscribeToAllValueChanges();
    this.sellerInfo();
  }

  ngAfterViewInit() {
    this.fg.setValidators(this.itemService.validateForm.bind(this));
    // if item has been modified check its validity
    if (this.vendorService.updatedMappedItems.has(this.item.id)) {
      this.fg.updateValueAndValidity();
    }

    if (this.item.status.skuStatus === ListingSkuStatusNamesEnum.archived) {
      this.fg.disable({ emitEvent: false, onlySelf: true });
    }

    if (this.item.errors) {
      this.fg.setErrors({ backendErrors: true });
    }

    // removes prefix from masked elements when null
    this.maskElements.toArray().forEach(queryElem => this.checkPrefix(queryElem.nativeElement));
  }

  disableControls() {
    const fgControlKeys = Object.keys(this.fg.getRawValue());
    fgControlKeys.forEach(control => {
      if (['cost', 'unitPrice'].includes(control) && this.isFieldDisabled()) {
        this.fg.controls[control].disable({ emitEvent: false });
      }
    });
  }

  subscribeToAllValueChanges() {
    const fgControlKeys = Object.keys(this.fg.getRawValue());
    fgControlKeys.forEach(control => {
      this.valueChangesSubscription.add(
        this.fg.controls[control].valueChanges
          .pipe(
            distinctUntilChanged()
          )
          .subscribe(val => {
            const cost = this.fg.get('cost').value;
            const checkDq = this.item.prices.some(res => res.unitPrice > 0);
            const checkDqPrice = this.item.prices.some(res => res.unitPrice >= cost);

            if (checkDq) {
              if (checkDqPrice && this.vendorService.isSeller) {
                this.dqErr = true;
                this.errorPopupTitle = 'Warning';
                this.dqErrMessage = 'Quantity Discounts have been removed. Please contact your vendor manager ' +
                  'if you would like to set new Quantity Discounts.';
              } else {
                this.dqErr = false;
                this.showDqWarningPopUp = false;
              }
            }

            // we need this to update all views that are using the same formGroup
            // if not only current will be updated
            if (control !== 'prices' && this.fromItemSimple) {
              this.fg.controls[control].patchValue(val, {
                emitEvent: false,
                onlySelf: true,
                emitModelToViewChange: true
              });
            }

            if (control === 'status_skuStatus') {
              this.adjustInventoryBasedOnStatus(val);
            }

            if (control === 'inventory_availableToSell') {
              this.adjustStatusBasedOnInventory(val);
            }

            if (control === 'cost') {
              if (this.vendorService.isAdmin || this.vmItem) {
                this.fg.controls.unitPrice.patchValue(+val);
              }

              // toggle item activation according to the threshold
              if (this.item.reactivationThreshold) {
                if (+val <= this.item.reactivationThreshold &&
                  this.item.status.skuStatus !== ListingSkuStatusNamesEnum.active) {
                  this.fg.controls['status_skuStatus'].patchValue(ListingSkuStatusNamesEnum.active);
                } else if (val > this.item.reactivationThreshold &&
                  this.item.status.skuStatus !== this.fg.controls['status_skuStatus'].value) {
                  this.fg.controls['status_skuStatus'].patchValue(this.item.status.skuStatus);
                }
                if(this.vendorService.isSeller)
                  this.toggleDropdownOptionActivation(+val);
              }
            }

            this.applyChangetoUpdatedItem(control, val);
            this.adjustUpdatedMappedItems();
          }));
    });

    if (this.fromItemSimple) {
      this.patchEachDiscountChange();
    }
  }

  // we need this to keep item-simple on the list in sync with the popup
  patchEachDiscountChange() {
    const controlNames = Object.keys(this.pricesControlsArray[0].value); // ['fromQty', 'unitPrice'];
    this.pricesControlsArray.forEach((formG, i) => {
      controlNames.forEach(controlName => {
        this.valueChangesSubscription.add(
          this.pricesControlsArray[i].controls[controlName].valueChanges
            .pipe(
              distinctUntilChanged(),
              debounceTime(200)
            )
            .subscribe(val => {
              this.pricesControlsArray[i].controls[controlName]
                .patchValue(val, {
                  emitEvent: false,
                  onlySelf: true,
                  emitModelToViewChange: true
                });
            }));
      });
    });
  }

  adjustInventoryBasedOnStatus(val: ListingSkuStatusNamesEnum) {
    const inventoryControl = this.fg.controls['inventory_availableToSell'];
    if (val === ListingSkuStatusNamesEnum.soldOut) {
      inventoryControl.patchValue(0);
      inventoryControl.disable({ emitEvent: false, onlySelf: true });
    } else if (inventoryControl.disabled && this.itemRequest.inventory.newInventory === 0) {
      inventoryControl.patchValue(this.item.inventoryInfo.currentInventory.availableToSell);
      inventoryControl.enable({ emitEvent: false, onlySelf: true });
    }
  }

  adjustStatusBasedOnInventory(inventory: number) {
    const statusControl = this.fg.get('status_skuStatus');
    const unitPriceControl = this.fg.get('unitPrice');

    if (!inventory && statusControl.value !== ListingSkuStatusNamesEnum.soldOut) {
      // this.internalStatuses = [this.dropDowns.skuStatus.find(i => i.value === ListingSkuStatusValuesEnum.soldOut)];
      statusControl.patchValue(ListingSkuStatusNamesEnum.soldOut, { emitEvent: false, onlySelf: true });
      this.updatedItem.status.skuStatus = ListingSkuStatusNamesEnum.soldOut;
    }

    if (inventory > 0 && statusControl.value === ListingSkuStatusNamesEnum.soldOut) {
      this.internalStatuses = (!this.isStatusInCurrentArr(this.item.status.previousSkuStatus)) ?
        Object.assign([], this.dropDowns.skuStatus.filter(i => i.text === this.item.status.skuStatus))
        : this.itemService.statusesByRole;
      statusControl.patchValue(this.item.status.previousSkuStatus, { emitEvent: false, onlySelf: true });
      this.updatedItem.status.skuStatus = this.item.status.previousSkuStatus;
    }

  }

  applyChangetoUpdatedItem(control: string, val: any) {
    switch (control) {
      case 'inventory_availableToSell':
        if (this.item.inventoryInfo.currentInventory.availableToSell !== val) {
          this.itemRequest.inventory.newInventory = val;
          this.updatedItem.inventoryInfo.currentInventory.availableToSell = val;
        } else {
          delete this.itemRequest.inventory.newInventory;
        }
        break;
      case 'status_skuStatus':
        if (this.item.status.skuStatus !== val) {
          if (val === ListingSkuStatusNamesEnum.archived) {
            this.itemRequest.status.isArchived = true;
          } else {
            const skustatus = this.dropDowns.skuStatus.find(s => s.text === val);
            if (skustatus) {
              this.itemRequest.status.skuStatus = skustatus.value;
            }
            this.itemRequest.status.isArchived = false;
          }
          this.updatedItem.status.skuStatus = val;
        } else {
          delete this.itemRequest.status.skuStatus;
          delete this.itemRequest.status.isArchived;
        }
        break;
      case 'description':
        if (this.item.description !== val) {
          this.itemRequest.itemInformation.description = val;
          this.updatedItem[control] = val;
        } else {
          delete this.itemRequest.itemInformation.description;
        }
        break;
      case 'eta':
        val = Number(val);
        if (this.item.leadTimeDays !== val) {
          this.itemRequest.inventory.leadTimeDays = val;
          this.updatedItem.leadTimeDays = val;
        } else {
          delete this.itemRequest.inventory.leadTimeDays;
        }
        break;
      case 'unitPrice':
        val = Number(val);
        //If cost updates to initial price value then the
        //patched unit price must be set back to inital value in the item update request.
        this.updatedItem.unitPrice = val;
        if (this.item.unitPrice !== val) {
          this.itemRequest.pricing.unitPrice = val;
        } else {
          delete this.itemRequest.pricing.unitPrice;
        }
        break;
      case 'prices':
        const discountArr = [];

        val.forEach((p, i) => {
          p.fromQty = Number(p.fromQty);
          p.unitPrice = Number(p.unitPrice);

          if (p.fromQty || p.unitPrice) {
            discountArr.push({ qty: p.fromQty, price: p.unitPrice });
          }
        });

        this.updatedItem.prices = val;
        const change = discountArr.some((p, i) =>
          !this.item.prices[i] ||
          this.item.prices[i].fromQty !== p.qty ||
          this.item.prices[i].unitPrice !== p.price
        );
        if (change || discountArr.length !== this.item.prices.length) {
          this.itemRequest.pricing.discountQtys = discountArr;
        } else {
          delete this.itemRequest.pricing.discountQtys;
        }
        break;
      case 'moq':
        val = (val !== '' ? Number(val) : null);
        if (this.item[control] !== val) {
          this.itemRequest.pricing.moq = val;
          this.updatedItem[control] = val;
        } else {
          delete this.itemRequest.pricing.moq;
          this.updatedItem[control]= val;
        }
        break;
      case 'mxq':
        val = (val !== '' ? Number(val) : null);
        if (this.item[control] !== val) {
          this.itemRequest.pricing.mxq = val;
          this.updatedItem[control] = val;
        } else {
          delete this.itemRequest.pricing.mxq;
          this.updatedItem[control] = val;
        }
        break;
      case 'cost':
        val = Number(val);
        if (this.item[control] !== val) {
          this.itemRequest.pricing.cost = val;
          this.updatedItem[control] = val;
        } else {
          delete this.itemRequest.pricing.cost;
        }
        break;
    }

  }

  adjustUpdatedMappedItems() {
    if (_.isEmpty(this.itemRequest.status) &&
      _.isEmpty(this.itemRequest.itemInformation) &&
      _.isEmpty(this.itemRequest.inventory) &&
      _.isEmpty(this.itemRequest.pricing)) {

      if (this.vendorService.updatedMappedItems.has(this.item.id)) {
        this.vendorService.updatedMappedItems.delete(this.item.id);
      }
    } else {
      this.vendorService.updatedMappedItems.set(this.item.id, { itemRequest: this.itemRequest, updateItem: this.updatedItem });
    }
  }

  getDefaultVendorItemRequest(): IVendorItemRequest {
    return {
      id: this.item.id,
      version: this.item.version,
      status: {},
      itemInformation: {},
      inventory: {},
      pricing: {}
    };
  }

  itemSetUp() {
    this.item.initialCost = this.item.initialCost ? this.item.initialCost : this.item.cost;
    if (this.vendorService.updatedMappedItems.has(this.item.id)) {
      const updatedItemShared = this.vendorService.updatedMappedItems.get(this.item.id);
      this.updatedItem = updatedItemShared.updateItem;
      this.itemRequest = updatedItemShared.itemRequest;
    } else {
      this.updatedItem = JSON.parse(JSON.stringify(this.item));
      this.itemRequest = this.getDefaultVendorItemRequest();
    }
    this.setUpStatusDropdownsAndPricesDetailed();
  }

  isStatusInCurrentArr(status?: string) {
    const checkStatus = (status) ? status : this.item.status.skuStatus;
    return this.itemService.statusesByRole.find(i => i.text === checkStatus);
  }

  setUpStatusDropdownsAndPricesDetailed(): void {
    this.businessStatusDropdown = this.dropDowns.businessStatus.filter(status => status.text !== 'All');
    this.item.prices = this.item.prices.filter(i => i.fromQty > 1);
    const length = this.item.prices.length >= 3 ? 3 : this.item.prices.length;
    this.item.prices = this.item.prices.slice(0, length);
  }

  updateSingleItem(item: IVendorItemInfo) {
    if (this.dqErr) {
      this.showDqWarningPopUp = true;
    } else {
      if (item.disabled) {
        return;
      }
      if (this.vendorService.updatedMappedItems.has(item.id)) {
        this.singleItemUpdated.emit(item);
      }
    }
  }

  errorPopupNotify(item: IVendorItemInfo) {
    this.showDqWarningPopUp = false;
    if (this.vendorService.isSeller) {
      const prices = <UntypedFormArray>this.fg.get(['prices']);
      prices.clear();
      this.dqErr = false;
      this.updateSingleItem(item);
    }
  }

  checkPrefix(elementRef) {
    if (elementRef.value && elementRef.value.length === 1) {
      elementRef.value = null;
    }
  }

  undoItemUpdates(e: Event): void {
    if (!this.vendorService.updatedMappedItems.has(this.item.id) && e!==null) {
      return;
    }
    if (e) {e.stopPropagation();}
    this.item.updatedNow = false;
    this.loader = true;
    this.vendorService.vendorItemsSavedInfo.updatedItems =
      this.vendorService.vendorItemsSavedInfo.updatedItems.filter(item => item.id !== this.item.id);
    this.vendorService.vendorItemsSavedInfo.vendorItemsRequest =
      this.vendorService.vendorItemsSavedInfo.vendorItemsRequest.filter(item => item.id !== this.item.id);

    this.vendorService.getVendorItemById([this.item.id])
      .subscribe((res: any) => {
        this.item = res.items[0];
        this.itemSetUp();
        this.resetForm(this.item);
        if (!this.vendorService.updatedMappedItems.size && this.vendorService.isShowAllUpdatedItems) {
          this.vendorService.isShowAllUpdatedItems = false;
        }
        this.loader = false;
      },
        (err) => {
          console.error('Error getting item by id', err);
          this.loader = false;
        });
  }

  resetForm(item: IVendorItemInfo) {
    this.itemChanged.emit(item);

    if (this.vendorService.updatedMappedItems.has(this.item.id)) {
      this.vendorService.updatedMappedItems.delete(this.item.id);
      this.itemRequest = this.getDefaultVendorItemRequest();
      this.updatedItem = JSON.parse(JSON.stringify(item));
    }

  }

  openEditDetailedMode(): void {
    this.item.selected = !this.item.selected;
    this.editDetailedMode.emit(this.item);
  }

  copyText(val: string): void {
    this.utilityService.copyTextToClipboard(val);
  }

  isFieldDisabled(): boolean {
    // The following will disable modifying cost for Sellers
    // if (!this.isAdmin) return true;
    return this.item.seller.name === 'Laptop Plaza' || this.item.seller.name === 'HUBX';
  }

  getManufacturerLink(): string {
    if (this.item.manufacturerLogoUrl.length > 0) {
      return this.item.manufacturerLogoUrl;
    } else {
      return '/assets/images/item-placeholder.svg';
    }
  }

  toggleArchiveStatus(statusValue: string) {
    this.fg.get('status_skuStatus').patchValue(statusValue);
    this.internalStatuses = (!this.isStatusInCurrentArr(statusValue)) ?
      Object.assign([], this.dropDowns.skuStatus.filter(i => i.text === statusValue))
      : this.itemService.statusesByRole;
  }

  archiveItem() {
    this.toggleArchiveStatus(ListingSkuStatusNamesEnum.archived);
    this.fg.disable({ emitEvent: false });
  }

  restoreItem() {
    this.enableControlsByRole();
    this.toggleArchiveStatus(this.item.status.previousSkuStatus);
  }

  enableControlsByRole() {
    const originalFG = this.itemService.generateItemFormGroup(this.item);
    const allControlNames = Object.keys(originalFG.controls);
    allControlNames.forEach(controlName => {
      if (originalFG.controls[controlName].enabled) {
        this.fg.controls[controlName].enable({ emitEvent: false });
      }
    });
  }

  ngOnDestroy() {
    this.dqErr = false;
    if (!this.fromItemSimple && this.fg) {
      // this class is inherited from item-detail we clear validators here
      // so new ones get created next time and not double
      this.fg.clearValidators();
    }

    this.valueChangesSubscription.unsubscribe();
  }

  getReductionRuleDisplayValue() {
    if (this.itemService.costReductionRule?.length) {
      const ruleApplied = this.itemService.costReductionRule
        .find((rule) => this.item.cost >= rule.minCost && this.item.cost <= rule.maxCost);
      if (ruleApplied) {
        return (ruleApplied.isPercent) ? `${ruleApplied.reductionAmount}%` : `$${ruleApplied.reductionAmount}`;
      }
    }
    return '';
  }

  verifyToShowCostPopup(): boolean {
    const statusControl = this.fg.get('status_skuStatus');
    const costControl = this.fg.get('cost');
    if ([ListingSkuStatusValuesEnum.removed, ListingSkuStatusValuesEnum.all].includes(this.vendorService.search.filters.skuStatus) &&
      statusControl.value !== ListingSkuStatusNamesEnum.active &&
      (this.item.reactivationThreshold && this.item.reactivationThreshold <= costControl.value)) {
      return true;
    }
    return false;
  }

  isRemovedSelected() {
    const statusControl = this.fg.get('status_skuStatus');
    return statusControl.value === ListingSkuStatusNamesEnum.removed &&
      [ListingSkuStatusValuesEnum.removed, ListingSkuStatusValuesEnum.all].includes(this.vendorService.search.filters.skuStatus);
  }

  handleInternalStatuses() {
    if (this.item.reactivationThreshold && this.vendorService.isSeller) {
      if (![ListingSkuStatusValuesEnum.removed, ListingSkuStatusValuesEnum.all].includes(this.vendorService.search.filters.skuStatus)) {
        this.internalStatuses = this.internalStatuses.filter(x => x.value !== ListingSkuStatusValuesEnum.removed);
      }
      if (this.isRemovedSelected()) {
        this.toggleDropdownOptionActivation(this.item.cost);
      }
    }
  }

  toggleDropdownOptionActivation(cost: number) {
    const dropdownOptionDisabled = cost <= this.item.reactivationThreshold ? false : true;
    this.internalStatuses.forEach(iStatus => {
      iStatus.dropdownOptionDisabled = dropdownOptionDisabled;
    });
  }

  sellerInfo() {
    this.item.disabled = false;

    if (this.vendorService.isVendorManager) {
      let vendor = this.vendorService.vendorManagerVendors.find(x => x.vendorId == this.item.seller.id);
      if (vendor) {
        this.vmItem = true;
        this.sellerName = this.item.seller.name;
      } else {
        this.vmItem = false;
        this.item.disabled = true;
        let vendorManager = this.itemService.vendorManagerMap?.get(this.item.seller.id);
        this.sellerName = vendorManager ? `${vendorManager}'s vendor`: 'Unassigned vendor';
      }
    }

    this.vendorService.isSeller ? this.sellerName = '' : '';
    this.vendorService.isAdmin ? this.sellerName = this.item.seller.name : '';
  }

  applyReactivationThreshold(){
    if(this.item.reactivationThreshold)
      this.fg.controls.cost.setValue(this.item.reactivationThreshold);
    //Hide tooltip after button click then enable on hover/focus
    this.reduceTooltipShow = false;
    setTimeout(()=> {this.reduceTooltipShow = true;},100);
  }

  addReactivationCount(){
    this.updateReactivationCount.emit();
  }
}
