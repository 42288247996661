import { environment } from './../../../../../environments/environment';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { INotificationVendor } from '../../../../vendor/interface/INotificationVendor';
import { NotificationCenterVendorService, TabsEnum } from '../notification-center-vendor.service';
import { NotificationTypesEnum, eNotificationAction } from '../../../../shared/interfaces/INotificationDetail';
import { IHttpErrorResponse } from '../../../../vendor/interface/IHttpErrorResponse';
import { UtilityService } from '../../../../core/utility.service';
import { LoaderService } from '../../../../shared/services/loader.service';
import { SharedService } from '../../../../shared/shared.service';
import { IErrorModal } from '../../../interface/IErrorModal';
import { VendorService } from '../../../vendor.service';
import { CustomCurrencyPipe } from '../../../../shared/pipes/currency.pipe';
import { ErrorModalComponent } from '../../../../shared/ui-components/error-modal/error-modal.component';
import { NgIf } from '@angular/common';

const positiveIntRegex = new RegExp(/^[1-9]+[0-9]*$/);
const currencyRegex = new RegExp(/^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9]?)?$/);

export interface INotifiationForm {
  pdActionYes: boolean;
  pdActionNo: boolean;
  pdValue: number;
  uIActionYes: boolean;
  uIActionNo: boolean;
  uIValue: number;
}

@Component({
    selector: 'app-notification-popup',
    templateUrl: './notification-popup.component.html',
    styleUrls: ['./notification-popup.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, ErrorModalComponent, CustomCurrencyPipe]
})

export class NotificationPopupComponent implements OnInit, OnDestroy {
  @Input() notification: INotificationVendor;
  @Input() errorModal: IErrorModal;
  @Output() closeModal = new EventEmitter<null>();
  @Output() updateTab = new EventEmitter<TabsEnum>();

  imageBaseUrl = environment.imageBaseUrl;
  isShowBottomControls = true;
  isShowDeclineNotifying = false;
  isShowNotify = false;
  notifiedUsers = 0;
  refusalsCount = 0;
  requestsCount = 0;
  selectionCount = 0;
  copied = 'Copy';
  errors: string;
  unitPrice: number;
  clearDq: boolean;
  showDqWarning: boolean;
  dqErrors: string;

  fg: FormGroup = new FormGroup({
    'pdActionYes': new FormControl(),
    'pdActionNo': new FormControl(),
    'pdValue': new FormControl(),
    'uIActionYes': new FormControl(),
    'uIActionNo': new FormControl(),
    'uIValue': new FormControl(),
    'selection': new FormControl(this.selectionCount,
      [Validators.required, Validators.min(1), Validators.max(2)])
  });

  constructor(private _notificationCenterSvc: NotificationCenterVendorService,
              private utilityService: UtilityService,  private loaderService: LoaderService,
              private sharedService: SharedService,
              private vendorService: VendorService) { }

  ngOnInit() {
    this.loaderService.setLoaderVisible(false);
    this.setFormValidators();
  }

  closePopup(): void {
    this.closeModal.emit();
  }

  notify(): void {
    const fgValue: INotifiationForm = this.fg.value;

    // update price
    if (fgValue.pdActionYes) {
      ++this.requestsCount;

      let pdPayload = {}
      
      if (this.clearDq) {
        pdPayload = {
          id: this.notification.vendorItem.id,
          itemCode: this.notification.vendorItem.vendorItemCode,
          cost: fgValue.pdValue,
          moq: this.notification.vendorItem.moq,
          mxq: this.notification.vendorItem.mxq,
          discountQtys: []
        }
      } else {
        pdPayload = {
          id: this.notification.vendorItem.id,
          itemCode: this.notification.vendorItem.vendorItemCode,
          cost: fgValue.pdValue,
          moq: this.notification.vendorItem.moq,
          mxq: this.notification.vendorItem.mxq
        }
      }

      this.dropPrice(pdPayload);
    }

    // reject price drop
    if (fgValue.pdActionNo) {
      ++this.requestsCount;
      const pdRejectPayload = new Array<any>({
          itemId: this.notification.vendorItem.id,
          actionType: eNotificationAction.add,
          notificationType: NotificationTypesEnum.priceDropRejected
      });

      this.rejectAction(pdRejectPayload);
    }

    // update moreInventory
    if (fgValue.uIActionYes) {
      ++this.requestsCount;
      const uIPayload = {
        id: this.notification.vendorItem.id,
        itemCode: this.notification.vendorItem.vendorItemCode,
        newInventory: fgValue.uIValue,
        onHand: this.notification.vendorItem.inventoryInfo.currentInventory.onHand,
        onRoute: this.notification.vendorItem.inventoryInfo.currentInventory.enRoute,
        leadTimeDays: this.notification.vendorItem.leadTimeDays
      };
      this.updateMoreInventory(uIPayload);
    }

    // reject moreInventory
    if (fgValue.uIActionNo) {
      ++this.requestsCount;
      const uIRejectPayload = new Array<any>({
          itemId: this.notification.vendorItem.id,
          actionType: eNotificationAction.add,
          notificationType: NotificationTypesEnum.moreInventoryRejected
      });
      this.rejectAction(uIRejectPayload);
      }

      this.isShowBottomControls = false;
      this.isShowNotify = true;
  }

  notifyGate() {
    if (this.clearDq) {
      this.showDqWarning = true;
    } else {
      this.showDqWarning = false;
      this.notify();
    }
  }

  errorPopupNotify() {
    this.showDqWarning = false;
    this.notify();
  }

  showDeclineNotifying(): void {
    this.isShowBottomControls = false;
    this.isShowDeclineNotifying = true;
  }

  cancelDeclineNotifying(): void {
    this.isShowBottomControls = true;
    this.isShowDeclineNotifying = false;
  }

  declineNotifying(): void {
    // console.log('declined');
  }

  // All validation is here
  setFormValidators() {
    const selection = this.fg.get('selection');
    const pdActionYes = this.fg.get('pdActionYes');
    const pdActionNo = this.fg.get('pdActionNo');
    const pdValueControl = this.fg.get('pdValue');
    pdValueControl.disable();

    const uIActionYes = this.fg.get('uIActionYes');
    const uIActionNo = this.fg.get('uIActionNo');
    const uIValueControl = this.fg.get('uIValue');
    uIValueControl.disable();

    const cost = this.notification.vendorItem.cost;

    this.notification.vendorItem.prices?.forEach(res => {
      this.unitPrice = res.unitPrice;
    });

    // If price drop checkbox is yes,
    // the input is required
    pdActionYes.valueChanges
    .subscribe( val => {

      if (val) {
        selection.setValue(++this.selectionCount);
        pdValueControl.enable();
        pdValueControl.setValidators([
          Validators.required,
          Validators.pattern(currencyRegex),
          Validators.max(cost - 1),
          // Validators.min(this.unitPrice + 1)
        ]);

        const checkDq = this.notification.vendorItem.prices.some(res => res.unitPrice > 0);

        pdValueControl.valueChanges.subscribe(res => {
          const pattern = /^\d+$/;
          const checkDecimal = pattern.test(res);

          (res >= cost && res !== null || checkDecimal) ? 
          this.errors = 'Value has to be $' + (cost - 1) + ' or less' : '';

          if (checkDq && res) {

            if (res <= this.unitPrice && this.vendorService.isSeller) {
              this.clearDq = true;
              this.dqErrors = 'Quantity Discounts have been removed. Please contact your vendor manager' +
              ' if you would like to set new Quantity Discounts.';
            } else if (checkDq && res <= this.unitPrice && 
              (this.vendorService.isAdmin || this.vendorService.isVendorManager)) {
              this.clearDq = true;
              this.dqErrors = 'Quantity Discounts have been removed.';
            } else {
              this.clearDq = false;
              this.dqErrors = null;
            }
          }

        });

        // both are selected yes and no
        // unselect no
        if (pdActionNo.value) {
          pdActionNo.setValue(false);
        }
      } else {
        selection.setValue(--this.selectionCount);
        pdValueControl.setValue(null);
        pdValueControl.setValidators(null);
        pdValueControl.disable();
        this.errors = null;
      }
      pdValueControl.updateValueAndValidity();
    });

    // If update inventory is yes,
    // the input is required
    uIActionYes.valueChanges
    .subscribe( val => {
      if (val) {
        selection.setValue(++this.selectionCount);
        uIValueControl.enable();
        uIValueControl.setValidators([
          Validators.required,
          Validators.pattern(positiveIntRegex),
          Validators.min(this.notification.vendorItem.inventoryInfo.currentInventory.availableToSell + 1)
        ]);

        // both are selected yes and no
        // unselect no
        if (uIActionNo.value) {
          uIActionNo.setValue(false);
        }
      } else {
        selection.setValue(--this.selectionCount);
        uIValueControl.setValue(null);
        uIValueControl.setValidators(null);
        uIValueControl.disable();
      }

      uIValueControl.updateValueAndValidity();
    });

    pdActionNo.valueChanges
    .subscribe( val => {
      if (val) {
        selection.setValue(++this.selectionCount);
        if ( pdActionYes.value) {
          // unselect yes
          pdActionYes.setValue(false);
        }
      } else {
        selection.setValue(--this.selectionCount);
      }
    });

    uIActionNo.valueChanges
    .subscribe( val => {
      if (val ) {
        selection.setValue(++this.selectionCount);
        if (uIActionYes.value) {
          // unselect yes
          uIActionYes.setValue(false);
        }
       } else {
          selection.setValue(--this.selectionCount);
      }
    });


  }

  copyText(event: any, val: string): void {
    event.stopPropagation();
    event.preventDefault();
    this.utilityService.copyTextToClipboard(val);
    this.copied = 'Copied!';
  }

  updateMoreInventory(payload) {
    this._notificationCenterSvc.updateInventoryRequest(payload)
    .subscribe(
      (resp) => {
        this.notifiedUsers += +resp;
        --this.requestsCount;
        this.updateTab.emit();
      },
      (err: IHttpErrorResponse) => {
        --this.requestsCount;
        console.error('Server error updating inventory', err);
      });
  }

  dropPrice(payload) {
    this._notificationCenterSvc.dropPriceRequest(payload)
    .subscribe(
      (resp) => {
        this.notifiedUsers += +resp;
        --this.requestsCount;
        this.updateTab.emit();
      },
      (err: IHttpErrorResponse) => {
        --this.requestsCount;
        console.error('Error droping price', err);
        if(err.error && err.error.errors && err.error.errors.length > 0)
          err.error.error = err.error.errors[0].errorMessage;
        else
          err.error.error = 'Something went wrong.';
        this.sharedService.handleVendorHttpError(err,this.errorModal);
      });
  }

  rejectAction(payload) {
    this._notificationCenterSvc.rejectNotificationRequest(payload)
    .subscribe(
      (resp) => {
        this.refusalsCount += +resp;
        this.notifiedUsers += +resp;
        --this.requestsCount;
        this.updateTab.emit(); // should be removed from all notif tab
      },
      (err) => {
        --this.requestsCount;
        console.error('Server error rejecting notification', err);
      });
  }

  ngOnDestroy() {
    this.clearDq = false;
    this.loaderService.setLoaderVisible(true);
  }
}
