<div class="item-wrap">
	<div class="item">
		<div class="item-row __top">
			<div class="item-logo">
				<div *ngIf="!item.manufacturerLogoUrl">
					<img [src]="imageBaseUrl + '/assets/images/item-placeholder.svg'" >
				</div>
				<div *ngIf="item.manufacturerLogoUrl">
					<img src="{{item.manufacturerLogoUrl}}" >	
				</div>
							
			</div>
			<div class="item-exw">
				<div class="item-exw-name">EXW</div>
				<div class="item-exw-value">{{ item.exwPoint }}</div>
			</div>
		</div>
		<div class="item-row __middle">
			<div class="item-desc">
				<span>
					{{item.description}}&nbsp; - &nbsp;
					<span class="copy-number" (mouseover)="itemsService.copyState()"
					(click)=itemsService.copyText($event,item.partNumber)>{{item.partNumber}}
					<span ngModel="copied" ngDefaultControl>{{ itemsService.copied }}</span>
				  </span>
				</span>
			</div>
		</div>
		<div class="item-row">
			<div class="item-col">
				<div class="item-col-name">Price</div>
				<div class="item-col-value">{{item.priceSold | currency:'USD':'symbol-narrow'}}</div>
			</div>
			<div class="item-col">
				<div class="item-col-name">QTY</div>
				<div class="item-col-value">{{item.quantitySold}}</div>
			</div>
			<div class="item-col">
				<div class="item-col-name">Date</div>
				<div class="item-col-value">{{item?.purchaseDate | date:'mediumDate'}}</div>
			</div>
			<div class="item-col __total">
				<div class="item-col-name">Total</div>
				<div class="item-col-value">{{item.priceSold * item.quantitySold | currency:'USD':'symbol-narrow'}}</div>
			</div>
		</div>
	</div>
</div>