import { OfferService } from './../../user/offers/offer.service';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';

import { CartService } from '../../buyer/cart/cart.service';
import { PageErrorService } from '../../page-error/page-error.service';
import { ItemsService } from '../services/items.service';
import { UtilityService } from '../../core/utility.service';
import { TopService } from '../services/top.service';
import { environment } from '../../../environments/environment';
import { IItemAttribute } from '../../buyer/interfaces/IItemAttribute';
import { InventoryLimitPipe } from '../pipes/inventory-limit.pipe';
import { IBuyerErrorModal } from '../../buyer/interfaces/IBuyerErrorModal';
import { SessionService } from '../../services/session.service';
import * as models from '../../buyer/interfaces/model';
import { SharedService } from '../shared.service';
import { IItem, eNotificationStatusBuyer } from '../../buyer/interfaces/model';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';
import { NotificationTypesEnum, eNotificationAction, NotificationFiltersEnum } from '../interfaces/INotificationDetail';
import { NotificationCenterBuyerService } from '../../buyer/notification-center/notification-center.service';
import * as _ from 'lodash';
import { GuestService } from '../../shared/services/guest.service';
import { AuthService } from '../../auth/auth.service';
import { StaticService } from '../../static/static.service';
import { UserService } from '../../user/user.service';
import { Router } from '@angular/router';
import { OfferStatusEnum } from '../../buyer/enums/offerStatusEnum';
import { CustomCurrencyPipe } from '../pipes/currency.pipe';
import { OnlyDigitsDirective } from '../directives/only-digits.directive';
import { ItemAttributesComponent } from '../../buyer/catalog/item-attributes/item-attributes.component';
import { FormsModule } from '@angular/forms';
import { PastPurchaseTitleComponent } from '../past-purchase-title/past-purchase-title.component';
import { NgClass, NgIf, NgStyle, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'app-listing-view-item',
    templateUrl: './listing-view-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./listing-view-item.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, PastPurchaseTitleComponent, NgStyle, NgFor, FormsModule, ItemAttributesComponent, OnlyDigitsDirective, DatePipe, CustomCurrencyPipe]
})
export class ListingViewItemComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  cartIsDone$: Subject<boolean> = new Subject<boolean>();

  @Input() item: models.IItem;
  @Input() source: string;
  @Input() isMobile: boolean;
  @Input() showRestrictedCountryPopup: boolean;
  @Output() onError = new EventEmitter<IBuyerErrorModal>();
  @Output() itemDetailedMode = new EventEmitter<models.IItem>();
  @Output() gradingGuideEmitter = new EventEmitter<models.IItem>();
  @Output() removeItem = new EventEmitter<string>();
  @Input() calledFromNotifCenter = false;
  @Input() currentNotFilter: NotificationFiltersEnum;
  @Output() notificationTooltipEmitter = new EventEmitter<{ item: models.IItem, event: Event }>();
  @Output() waitlistTooltipEmitter = new EventEmitter<{ item: models.IItem, event: Event, isShowWaitlist: boolean }>();

  NotificationTypesEnum = NotificationTypesEnum;
  NotificationFiltersEnum = NotificationFiltersEnum.all;
  itemsAvailable: number;
  todaysDeal = 'Today\'s Deals';
  priceDrop = 'Price Drop';
  justLaunched = 'Just Launched';
  visualCondition = 'Visual Condition';
  openMoreInfo = '';
  copied = 'Copy';
  zeroQtyAlert = false;
  notEnoughQty = false;
  notEnoughQtyMessage = '';
  notEnoughQtyTitle = '';
  notEnoughMOQ = false;
  openItemDetails = false;
  minimizeDetails = false;
  showItemMOQ = false;
  showItemMXQ = false;
  showInputMXQ = false;
  openMobileInfo = '';
  animateOut = false;
  pubServiceSubscription: Subscription;
  itemListErrorModal: IBuyerErrorModal = {
    bodyText: '',
    title: '',
    isShown: false
  };
  deleteSpinner = false;
  isOfferMade = false;
  imageBaseUrl = environment.imageBaseUrl;
  @Input() selectedItem: IItem = {};
  @Input() displayMakeOfferBtn = true;

  constructor(
    public cartService: CartService,
    public pageErrorService: PageErrorService,
    private pubSubService: PubSubService,
    public itemsService: ItemsService,
    public topService: TopService,
    public utilService: UtilityService,
    public userService: UserService,
    private inventoryLimitPipe: InventoryLimitPipe,
    public sessionService: SessionService,
    private sharedService: SharedService,
    public notifCenterBuyer: NotificationCenterBuyerService,
    public guestService: GuestService,
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    public staticService: StaticService,
    private router: Router,
    public offerService: OfferService) { }

  ngOnInit() {
    if (!this.item.prevPrice) {
      this.item.prevPrice = this.item.baseUnitPrice;
    }

    this.setupPubSub();
    this.prepareVisibleIcons();
    this.checkItemInCart();
    this.cartService.cartChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.checkItemInCart();
    });

    this.mapNotificationInfo();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setupPubSub(): void {
    this.pubServiceSubscription = this.pubSubService.sharedSubject.pipe(takeUntil(this.destroy$)).subscribe(myEvent => {
      if (myEvent.name === SharedSource.offerSummary) {
        this.detectChangesInTemplate();
      }
      if (myEvent.name === SharedSource.newPriceUpdated) {
        if (myEvent.data.id === this.item.id) {
          this.item = {
            ...myEvent.data,
            pastPurchaseInfo: this.item.pastPurchaseInfo
          }
          this.itemsService.updateItemPrice(this.item);
          this.cdr.markForCheck();
        }
      }
      if (myEvent.name === SharedSource.refreshNotificationItem) {
        if (myEvent.data === this.item.id) {
          this.mapNotificationInfo();
          this.cdr.markForCheck();
        }
      }
    });
  }

  toggleMoreInfo(openMoreInfo: string, id?: string): void {
    if (undefined !== id) {
      this.openMoreInfo = id;
    } else {
      this.openMoreInfo = '';
    }
  }

  openGradingGuide(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.openItemDetails = true;
    this.gradingGuideEmitter.emit(this.item);
  }

  prepareVisibleIcons(): void {
    const visConditionAttribute = this.item.attributes.find(a => a.label === this.visualCondition);
    if (visConditionAttribute) {
      visConditionAttribute.hide = true;
    }
  }

  getVisibleAttributes(): IItemAttribute[] {
    return this.item.attributes.filter(a => !a.hide);
  }

  updateItemPrice(): void {
    if (!this.sessionService.userCanBuy) {
      return;
    }

    if (!this.item.qty || this.item.qty === 0) {
      this.item.qty = this.item.previousQuantityAdded;
      return;
    }

    this.itemsService.updateItemPrice(this.item);
  }

  redColor(): boolean {
    const red = this.item.attributes.find(a => a.label === this.justLaunched);
    if (red) {
      return true;
    }
  }

  greenColor(): boolean {
    const green = this.item.attributes.find(a => a.label === this.priceDrop);
    if (green) {
      return true;
    }
  }

  addToCart(ignoreExw?: boolean): void {
    if (this.userService.isGuest) {
      this.userService.guestPop();
      return;
    }

    if (this.item.loading || !this.sessionService.userCanBuy) {
      return;
    }

    if (this.itemsService.isItemRestrictedForCountry(this.item)) {
      this.sharedService.itemRestrictedForCountry(this.item);
      return;
    }

    if (!this.item.exwPoint.match(/miami/i) && !ignoreExw) {
      this.itemsService.showExwPopUp = true;
      this.itemsService.selectedItemExwPopup = this.item;
      return;
    }

    this.item.loading = true;
    if (this.item.qty.toString() === '0') {
      this.zeroQtyAlert = true;
      this.itemListErrorModal.bodyText = 'Quantity must be more than 0.';
      this.itemListErrorModal.title = 'Quantity Error';
      this.itemListErrorModal.isShown = true;
      this.onError.emit(this.itemListErrorModal);

      if (this.item.previousQuantityAdded) {
        this.item.qty = this.item.previousQuantityAdded;
        this.item.added = true;
        this.item.beingEdited = false;
      }

      this.item.loading = false;
      return;
    }

    if (this.item.inCart) {
      this.cartService.updateCart(this.findCartItem(), this.item.qty, this.item.unitPrice).pipe(takeUntil(this.cartIsDone$)).subscribe(
        (data) => {
          this.cartService.showAddedModal();
          this.item.added = true;
          this.item.beingEdited = false;
          this.item.previousQuantityAdded = this.item.qty;

          this.item.inCart = true;
          this.cartService.cartList = data;
          this.cartService.updateCartItemData(this.item);
          this.item.reservedQty > 0 ? this.item.added = false : this.item.added = true;
          this.topService.loading = false;
          this.item.loading = false;
          this.sharedService.itemNewPrice = this.item;
          this.cdr.markForCheck();
          if (this.destroy$.closed) {
            this.cartIsDone$.next(true);
            this.cartIsDone$.unsubscribe();
          }
        },
        (err) => {
          if (err.status === 400) {
            this.prepareErrorMessages(err.error);
            return;
          }

          this.item.loading = false;
          this.topService.loading = false;
          this.sharedService.handleBuyerHttpError(err, this.itemListErrorModal, true);
          this.cdr.markForCheck();
          if (this.destroy$.closed) {
            this.cartIsDone$.next(true);
            this.cartIsDone$.unsubscribe();
          }
        }
      );
    } else {
      this.cartService.addToCart(this.item.id, this.item.qty).pipe(takeUntil(this.cartIsDone$)).subscribe(
        (data) => {
          this.cartService.showAddedModal();
          this.item.previousQuantityAdded = this.item.qty;
          this.item.inCart = true;
          this.cartService.cartList = data;
          this.cartService.updateCartItemData(this.item);
          this.item.reservedQty > 0 ? this.item.added = false : this.item.added = true;
          this.topService.loading = false;
          this.item.loading = false;
          if (this.router.url.match(/past_purchases|just_sold|live_offers/)) {
            this.sharedService.itemNewPrice = this.item;
          }
          this.cdr.markForCheck();
          if (this.destroy$.closed) {
            this.cartIsDone$.next(true);
            this.cartIsDone$.unsubscribe();
          }
        },
        (err) => {
          if (err.status === 400) {
            this.prepareErrorMessages(err.error);
            return;
          }

          this.item.loading = false;
          this.topService.loading = false;
          this.sharedService.handleBuyerHttpError(err, this.itemListErrorModal, true);
          this.cdr.markForCheck();
          if (this.destroy$.closed) {
            this.cartIsDone$.next(true);
            this.cartIsDone$.unsubscribe();
          }
        }
      );
    }
  }

  removeItemFromCart(): void {

    if (this.deleteSpinner) {
      return;
    }

    this.deleteSpinner = true;
    this.cartService.deleteFromCart(this.findCartItem()).subscribe(
      (data) => {
        this.cartService.cartList = data;

        if (this.item.moq && this.item.moq > 1) {
          this.item.qty = this.item.moq;
        } else {
          this.item.qty = 1;
        }
        this.item.previousQuantityAdded = this.item.qty;
        this.sharedService.itemNewPrice = this.item;

        this.cartService.updateCartItemData(this.item);
        this.item.added = false;
        this.item.beingEdited = false;
        this.item.inCart = false;
        this.deleteSpinner = false;
        if (this.router.url.match(/past_purchases|just_sold|live_offers/)) {
          this.sharedService.itemNewPrice = this.item;
        }
      },
      (err) => {
        if (err.status === 400) {
          this.prepareErrorMessages(err.error);
          return;
        } else {
          this.sharedService.handleBuyerHttpError(err, this.itemListErrorModal, true);
        }
        this.deleteSpinner = false;
      }
    );
  }

  findCartItem(): number {
    for (let i = 0; i < this.cartService.cartList.length; i++) {
      if (this.item.id === this.cartService.cartList[i].item.id) {
        return this.cartService.cartList[i].lineSequence;
      }
    }
  }

  // method to make sure items that are currently in cart have the same data
  checkItemInCart(): void {
    if (this.cartService.cartList) {
      let cartPosition = -1;
      for (let i = 0; i < this.cartService.cartList.length; i++) {
        if (this.cartService.cartList[i].item.id === this.item.id) {
          cartPosition = i;
        }
      }

      if (cartPosition >= 0) {
        this.item.added = true;
        this.item.beingEdited = false;
        this.item.qty = this.cartService.cartList[cartPosition].quantity;
        this.item.inCart = true;
        this.item.unitPrice = this.cartService.cartList[cartPosition].unitPrice;
        this.item.reservedQty = this.cartService.cartList[cartPosition].reservedQty;
      } else {
        this.item.added = false;
        this.item.inCart = false;
        this.item.beingEdited = false;
        this.item.qty = 1;

        if (this.item.inventory.availableToSell < 1) {
          this.item.qty = 0;
        }

        if (this.item.moq > 1) {
          this.item.qty = this.item.moq;
        }

        this.item.previousQuantityAdded = this.item.moq;
      }
      this.cdr.markForCheck();
    }
  }

  editItemQty(): void {
    this.item.loading = false;
    this.item.previousQuantityAdded = this.item.qty;
    this.item.added = false;
    this.item.beingEdited = true;
  }

  selectText(event: any): void {
    this.item.qty = null;
    event.target.select();
  }

  onBlur(event: any): void {
    if (!this.item.qty) {
      this.item.qty = this.item.previousQuantityAdded ? this.item.previousQuantityAdded : 1;
    }
  }

  getNumberOfItemsAvailable(item: models.IItem): string {
    this.itemsAvailable = this.item.inventory.availableToSell;
    return (this.sessionService.isBuyer)
      ? this.inventoryLimitPipe.transform(this.itemsAvailable)
      : this.itemsAvailable.toString();
  }

  checkMoreThanOnHand(): boolean {
    return this.sessionService.isBuyer && this.item.inventory.onHand >= 300;
  }

  hideNegative(): boolean {
    return this.sessionService.isBuyer && this.item.inventory.onHand < 1 && this.item.inventory.enRoute < 1;
  }

  itemMOQTooltip(): void {
    this.showItemMOQ = !this.showItemMOQ;
  }

  itemMXQTooltip(v): void {
    switch (v) {
      case 1: this.showItemMXQ = !this.showItemMXQ; break;
      case 2: this.showInputMXQ = !this.showInputMXQ; break;
    }
  }

  checkMoreThanEnRoute(): boolean {
    return this.sessionService.isBuyer && this.item.inventory.enRoute >= 300 && this.item.inventory.onHand === 0;
  }

  prepareErrorMessages(error400: string): void {
    let filter = error400;
    if (error400.startsWith('This item has a Max Unit Quantity')) {
      filter = 'This item has a Max Unit Quantity';
    } else if (error400.startsWith('Quantity must be equal or greater than Minimum Order Quantity of')) {
      filter = 'Quantity must be equal or greater than Minimum Order Quantity of';
    }

    const newError = environment.errorMapping.filter(c => c.server.startsWith(filter));
    if (newError && newError.length > 0) {
      this.itemListErrorModal.title = newError[0].client.title;
      this.itemListErrorModal.bodyText = error400;
      this.itemListErrorModal.isShown = true;
      this.onError.emit(this.itemListErrorModal);
    } else {
      this.itemListErrorModal.bodyText = error400;
      this.itemListErrorModal.title = 'Important';
      this.itemListErrorModal.isShown = true;
      this.onError.emit(this.itemListErrorModal);


    }

    this.notEnoughQty = true;
    this.topService.loading = false;
    this.item.qty = this.item.previousQuantityAdded;
    this.item.loading = false;
    return;
  }

  calculateInventoryEnRoute(): string {
    if (this.sessionService.isBuyer) {
      if (this.item.inventory.enRoute + this.item.inventory.onHand > 300) {
        return (300 - this.item.inventory.onHand).toString() + '+';
      } else {
        return (this.item.inventory.enRoute).toString() + '';
      }
    } else {
      return (this.item.inventory.enRoute).toString() + '';
    }
  }

  isShowMobileInfoIcon(): boolean {
    for (let i = 0; i < this.item.attributes.length; i++) {
      const attr = this.item.attributes[i];
      if (attr.label !== 'Price Drop' && attr.label !== 'Just Launched' && attr.label !== 'Today\'s Deals') {
        return true;
      }
    }

    return false;
  }

  isSingleIconShown(attr: IItemAttribute): boolean {
    return attr.label === 'Warranty' || attr.label === 'Packaging' || attr.label === 'Condition' || attr.label === 'Restrictions';
  }

  showTooltip(id): void {
    this.openMobileInfo = id;
  }

  onClickedOutside(e: Event): void {
    this.openMobileInfo = '';
  }

  /**
 * @returns void
 */
  openModal(): void {
    this.openItemDetails = true;
    this.itemDetailedMode.emit(this.item);
  }

  /**
 * @param  {boolean} isCancel
 * @returns void
 */
  closeModal(isCancel: boolean): void {
    if (isCancel) {
      this.item.qty = this.item.previousQuantityAdded > 0 ? this.item.previousQuantityAdded : 1;
    }

    this.animateOut = true;
    setTimeout(() => {
      this.openItemDetails = false;
    }, 400);
  }

  submitNotification(event): void {
    if (this.topService.loading) {
      return;
    }

    if (this.userService.isGuest) {
      this.userService.guestPop();
      return;
    }

    this.item.notificationInfoObj.waitlist = !this.item.notificationInfoObj.waitlist;
    if (this.item.notificationInfoObj.waitlist !== this.item.notificationInfoObj.waitlistInitialValue) {
      if(event)
        this.openWaitlistTooltip(event);
      const action = this.item.notificationInfoObj.waitlist ? eNotificationAction.add : eNotificationAction.remove;
      const type = NotificationTypesEnum.waitlist;
      this.notifyMe(type, action, this.item.id);
    }
  }

  notifyMe(type, action, itemId) {
    this.notifCenterBuyer.notifyMe(type, action, itemId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          if (this.item.notificationInfoObj.waitlist) {
            this.item.notificationInfoObj.waitlistInitialValue = true;
            this.item.notificationInfo.push(NotificationTypesEnum.waitlist);
          } else {
            this.item.notificationInfoObj.waitlistInitialValue = false;
            this.item.notificationInfo = this.item.notificationInfo.filter(x => x !== NotificationTypesEnum.waitlist);
          }

          if (this.calledFromNotifCenter) {
            if (this.currentNotFilter !== NotificationFiltersEnum.archive ||
              (this.currentNotFilter === NotificationFiltersEnum.archive && action === eNotificationAction.remove)) {
              this.manageNotificationDetail(type, action);
            }
          }
          this.cdr.markForCheck();
        },
        (err) => {
          this.item.notificationInfoObj.waitlist = this.item.notificationInfoObj.waitlistInitialValue ? true : false;
          this.cdr.markForCheck();
        }
      );
  }

  openNotificationTooltip(event: any): void {
    event.preventDefault();
    this.topService.notificationTooltipHovered = true;
    const item = this.item;
    this.notificationTooltipEmitter.emit({ item, event });
    event.stopPropagation();
  }

  openWaitlistTooltip(event: any): void {
    event.preventDefault();
    const isShowWaitlist = true;
    const item = this.item;
    this.waitlistTooltipEmitter.emit({ item, event, isShowWaitlist });
    event.stopPropagation();
  }

  hideWaitlistTooltip(event: any): void {
    event.preventDefault();
    const isShowWaitlist = false;
    const item = this.item;
    this.waitlistTooltipEmitter.emit({ item, event, isShowWaitlist });
    event.stopPropagation();
  }

  mapNotificationInfo() {
    this.item.notificationInfoObj = {
      priceDrop: false,
      moreInventory: false,
      waitlist: false,
      priceDropInitialValue: false,
      moreInventoryInitialValue: false,
      waitlistInitialValue: false
    };

    if (this.item.notificationInfo) {
      if (this.item.notificationInfo.includes(NotificationTypesEnum.priceDrop)) {
        this.item.notificationInfoObj.priceDrop = true;
        this.item.notificationInfoObj.priceDropInitialValue = true;
      }
      if (this.item.notificationInfo.includes(NotificationTypesEnum.moreInventory)) {
        this.item.notificationInfoObj.moreInventory = true;
        this.item.notificationInfoObj.moreInventoryInitialValue = true;
      }
      if (this.item.notificationInfo.includes(NotificationTypesEnum.waitlist)) {
        this.item.notificationInfoObj.waitlist = true;
        this.item.notificationInfoObj.waitlistInitialValue = true;
      }
    }
  }

  manageNotificationDetail(type: NotificationTypesEnum, action: eNotificationAction) {
    const notifDetail = {
      isNew: null,
      notificationType: type,
      notificationStatus: (action === eNotificationAction.add) ? eNotificationStatusBuyer.waiting : null,
      notificationValue: null,
      timeStamp: null,
    };

    let counterOfEmpty = 0;
    this.item.notificationDetails.forEach((nd, i) => {
      if (nd.notificationType === type) {
        this.item.notificationDetails[i] = notifDetail;

        if (action === eNotificationAction.remove) {
          counterOfEmpty++;
        }
      } else if (nd.notificationStatus === null) {
        counterOfEmpty++;
      }
    });

    if (counterOfEmpty === 3) {
      this.removeItem.next(this.item.id);
    }
  }

  showGuestLogin() {
    if (this.userService.isGuest) {
      this.userService.guestPop();
    }
  }

  getNotificationType(filter) {
    return this.notifCenterBuyer.getNotificationType(filter);
  }

  detectChangesInTemplate(): void {
    this.cdr.markForCheck();
  }
}
