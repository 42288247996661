import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ItemFilterComponent } from '../item-filter.component';
import { FormGroup, FormControl, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedSource } from '../../../core/shared-source';
import { sortTypesEnum } from '../../enums/sort-types';
import { ISortingOptions } from '../../../buyer/interfaces/ISortingOptions';
import { Options as SliderOptions } from 'ng5-slider';
import { Attribute } from '../../../buyer/interfaces/Attribute';
import { debounceTime } from 'rxjs/operators';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { OnlyDigitsDirective } from '../../directives/only-digits.directive';
import { NgxMaskModule } from 'ngx-mask';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { ItemsBreadcrumbsMobileComponent } from '../../../buyer/catalog/items-breadcrumbs/items-breadcrumbs-mobile/items-breadcrumbs-mobile.component';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgFor, NgClass } from '@angular/common';
import { environment } from './../../../../environments/environment';

@Component({
    selector: 'app-item-filter-mobile',
    templateUrl: './item-filter-mobile.component.html',
    styleUrls: ['./item-filter-mobile.component.scss'],
    standalone: true,
    imports: [NgIf, ItemsBreadcrumbsMobileComponent, NgSwitch, NgSwitchCase, NgSwitchDefault, NgFor, RouterLinkActive, RouterLink, FormsModule, ReactiveFormsModule, NgClass, NgxMaskModule, OnlyDigitsDirective, NgxSliderModule]
})
export class ItemFilterMobileComponent extends ItemFilterComponent implements OnInit {
  sortTypesEnum = sortTypesEnum;
  manuControl: AbstractControl;
  condControl: AbstractControl;
  sortPicker: AbstractControl;
  sortByModelControl: AbstractControl;
  exwControl: AbstractControl;
  inStockControl: AbstractControl;
  fromManuDropDown: boolean = false;
  imageBaseUrl = environment.imageBaseUrl;
  @Output() onCloseClick: EventEmitter<any> = new EventEmitter<any>();

  filterFg: FormGroup = new FormGroup({
    manufacturer: new FormControl(),
    condition: new FormControl(),
    sortPicker: new FormControl(),
    sortByModel: new FormControl(),
    exw: new FormControl(),
    inStock: new FormControl(false),
  });

  // If you change maxPrice, make sure you set the condition at applyPriceRange()
  minPrice = 0;
  maxPrice = 10000;
  sliderMinPrice = 0;
  sliderMaxPrice = 10000;
  isMinMaxValid = true;
  plusSignSuffix: string = '';
  minPriceFrm = new FormControl();
  maxPriceFrm = new FormControl();

  priceRangeOptions: SliderOptions = {
    floor: 0,
    ceil: 10000,
    animate: false,
    hidePointerLabels: true,
    hideLimitLabels: true,
    noSwitching: true,
    enforceStep: false,
    enforceRange: false,
  };

  isPriceRangeModalOpened = false;

  get topFilterActiveMobile() {
    return this.topFilterActive;
  };

  ngOnInit() {
    this.subscribeToAllValueChanges();
    this.sortingOptions = this.getSortingOptions();
    this.resetAllDropdowns();

    this.subscriptions.push(
      this.pubSubService.sharedSubject.subscribe(
        mySharedValues => {
          if (mySharedValues.name === SharedSource.clearAllFilter) {
            this.resetAllDropdowns();
          }
          if (mySharedValues.name === SharedSource.itemsListChanged &&
            this.itemFilterService.userFilter) {
            this.patchMobileValues();
            this.minPrice = this.itemsService.itemsRequest.price ? this.itemsService.itemsRequest.price.minPrice : this.minPrice;
            this.maxPrice = this.itemsService.itemsRequest.price ? this.itemsService.itemsRequest.price.maxPrice : this.maxPrice;
            super.maxPriceSuffix();
          }
        })
    );
    this.onPriceChange();
    this.maxPriceSuffix();
  }

  onPriceChange() {
    
    this.subscriptions.push(
      this.minPriceFrm.valueChanges.pipe(
        debounceTime(700)
      ).subscribe(newMinPrice => {
        if(this.minPrice != newMinPrice){
          this.minPrice = newMinPrice;
          this.minPriceFrm.setValue(newMinPrice);
          this.maxPriceSuffix();
        }
      })
    );

    this.subscriptions.push(
      this.maxPriceFrm.valueChanges.pipe(
        debounceTime(700)
      ).subscribe(newMaxPrice => {
        if(this.maxPrice != newMaxPrice){
          this.maxPrice = newMaxPrice;
          this.maxPriceFrm.setValue(newMaxPrice);
          this.maxPriceSuffix();
        }
      })
    );
  }

  subscribeToAllValueChanges() {
    this.manuControl = this.filterFg.get('manufacturer');
    this.condControl = this.filterFg.get('condition');
    this.sortPicker = this.filterFg.get('sortPicker');
    this.sortByModelControl = this.filterFg.get('sortByModel');
    this.exwControl = this.filterFg.get('exw');
    this.inStockControl = this.filterFg.get('inStock');

    if (this.manuControl) {
      this.manuControl.valueChanges.subscribe(mID => {
        this.resetAllDropdowns();
        const manu = this.getManufacturersDataDropDown().find(m => m.id === mID);
        super.changeManufacturer(manu);
      });
    }

    if (this.condControl) {
      this.condControl.valueChanges.subscribe(c => {
        this.itemsService.skipConditions = true;
        const condition = this.itemsService.conditionAttributeValues.find(cond => cond.name === c);
        super.selectCondition(condition);
      });
    }

    if (this.sortPicker) {
      this.sortPicker.valueChanges.subscribe(s => {
        this.itemsService.skipConditions = true;
        const sortItem = this.sortingOptions.find(sItem => sItem.value === s);

        // reseting the sort
        if (!sortItem) {
          //updating the selected manufacturer 
          this.updateManufacturerDataSelectedManufacturer();

          this.itemsService.currentSort = sortTypesEnum.none;
          this.itemsService.getItems();
          return;
        }

        // uncheck sort by model
        this.sortByModelControl.setValue(false, { emitEvent: false });
        super.selectSort(sortItem);
      });
    }

    if (this.sortByModelControl) {
      this.sortByModelControl.valueChanges
        .subscribe(v => {
          this.itemsService.paginationInfo = null;
          //updating the selected manufacturer 
          this.updateManufacturerDataSelectedManufacturer();

          this.itemsService.skipConditions = true;

          if (v) {
            // reset other sort in dropdown if any
            this.selectedSort = undefined;
            this.sortPicker.setValue(sortTypesEnum.none, { emitEvent: false });
            super.sortByModel();
          } else {
            this.itemsService.getItems();
          }
        });
    }

    if (this.exwControl) {
      this.exwControl.valueChanges.subscribe(e => {
        const exwObj = this.itemsService.exwPoints.find(exw => exw.value === e);
        this.itemsService.skipConditions = false;
        super.selectExw(exwObj);
      });
    }

    if (this.inStockControl) {
      this.inStockControl.valueChanges
        .subscribe(v => {
          this.itemsService.paginationInfo = null;
          //updating the selected manufacturer 
          this.updateManufacturerDataSelectedManufacturer();

          this.itemsService.itemsRequest.inStock = v;
          this.itemsService.skipManufacturers = true;
          this.itemsService.skipConditions = false;
          this.itemsService.getItems();
        })
    }

    this.subscriptions.push(
      this.pubSubService.sharedSubject.subscribe(myEvent => {

        if (myEvent.name === SharedSource.itemsListFetched) {
          this.itemsListFeched = myEvent.data;
          this.setTopFilterActive(this._router.url);
        }
      })
    )

  }

  close(): void {
    this.onCloseClick.emit();
  }

  changeManu(manufacturer: Attribute) {
    this.fromManuDropDown = false;
    this.resetAllDropdowns();
    this.changeManufacturer(manufacturer);
  }

  clearAll(event: MouseEvent) {
    if (event) { event.stopPropagation(); }

    this.resetAllDropdowns();

    if (this.itemFilterService.selectedSearch) {
      this.resetSearch();
      return;
    }

    if (this.outletsService.isManufacturerOutlet()) {
      // this.changeManufacturer(this.itemFilterService.selectedManufacturer);
      this.changeManu(this.itemFilterService.selectedManufacturer)
      return;
    }
    // if it has first level(e.g all_laptops/traditional
    // traditional is first level) we need to keep it
    const navToReset = (this.itemFilterService.selectedFirstLevel) ?
      this.itemFilterService.selectedFirstLevel : this.itemFilterService.selectedZeroLevel;
    this.resetOriginalNav(navToReset, +(!!this.itemFilterService.selectedFirstLevel));
  }

  resetAllDropdowns() {
    if (this.manuControl && !this.fromManuDropDown) {
      this.manuControl.setValue('', { emitEvent: false });
      this.fromManuDropDown = false;
    }
    if (this.condControl) {
      this.condControl.setValue(this.itemsService.conditionAttributeValues[0].name, { emitEvent: false });
    }
    if (this.sortPicker) {
      this.isAsc = false;
      this.selectedSort = undefined;
      const sortSelection = (this.selectedSort) ? this.selectedSort.value : sortTypesEnum.none;
      this.sortPicker.setValue(sortSelection, { emitEvent: false });
    }
    if (this.sortByModelControl) {
      this.sortByModelControl.setValue(false, { emitEvent: false });
    }
    if (this.exwControl) {
      this.exwControl.setValue(this.itemsService.exwPoints[0].value, { emitEvent: false });
    }
    if (this.inStockControl) {
      this.inStockControl.setValue(this.itemsService.itemsRequest.inStock, { emitEvent: false });
    }
  }

  patchMobileValues() {
    const formValues = this.filterFg.getRawValue();
    super.patchValues();

    const values = {
      manufacturer: (this.itemFilterService.selectedManufacturer) ? this.itemFilterService.selectedManufacturer.id : '',
      condition: (this.itemFilterService.selectedCondition) ?
        this.itemFilterService.selectedCondition.name : this.itemsService.conditionAttributeValues[0].name,
      exw: (this.selectedEwx) ? this.selectedEwx.value :
        this.itemsService.exwPoints[0].value,
      inStock: this.itemsService.itemsRequest.inStock || false,
      sortPicker: formValues.sortPicker,
      sortByModel: formValues.sortByModel,
    };

    this.filterFg.setValue(values, { emitEvent: false });
  }

  openPriceRangeModal(): void {
    console.log("openPriceRangeModal()");
    this.isPriceRangeModalOpened = true;
  }

  closePriceRangeModal(): void {
    console.log("closePriceRangeModal()");
    this.isPriceRangeModalOpened = false;
  }

  applyPriceRange() {
    console.log("applyPriceRange()");
    this.itemsService.paginationInfo = null;
    if (this.minPrice === 0 && this.maxPrice === 10000) {
      this.itemsService.itemsRequest.price = null;
    } else if (this.minPrice === 0) {
      this.itemsService.itemsRequest.price = { minPrice: null, maxPrice: this.maxPrice }
    } else if (this.maxPrice === 10000) {
      this.itemsService.itemsRequest.price = { minPrice: this.minPrice, maxPrice: null }
    } else {
      this.itemsService.itemsRequest.price = { minPrice: this.minPrice, maxPrice: this.maxPrice }
    }

    //updating the selected manufacturer 
    this.updateManufacturerDataSelectedManufacturer();

    this.itemsService.getItems();
    this.isPriceRangeModalOpened = false;
  }

  clearPriceRange() {
    console.log("clearPriceRange()");
    this.itemsService.paginationInfo = null;
    //updating the selected manufacturer 
    this.updateManufacturerDataSelectedManufacturer();

    this.minPrice = 0;
    this.maxPrice = 10000;
    this.sliderMinPrice = 0;
    this.sliderMaxPrice = 10000;
    this.itemFilterService.minPrice = 0;
    this.itemFilterService.maxPrice = 10000;
    this.isMinMaxValid = true;
    this.itemsService.itemsRequest.price = null;
    this.itemsService.getItems();
    setTimeout(() => {
      this.plusSignSuffix = '+';
    }, 0);
  }

  adjustPriceInputs() {
    console.log("adjustPriceInputs()");
    this.minPrice = this.sliderMinPrice;
    this.maxPrice = this.sliderMaxPrice;
    this.itemFilterService.minPrice = this.sliderMinPrice;
    this.itemFilterService.maxPrice = this.sliderMaxPrice;
    this.isMinMaxValid = true;
    setTimeout(() => {
      this.maxPrice === 10000 ? this.plusSignSuffix = '+' : this.plusSignSuffix = '';
    }, 0);

  }
}
