<div class="btm-filter-wrap" [class.height-crop]="itemsService.isJustSoldUrl || itemsService.isPastPurchase"
  [class.__show]="itemFilterService.isFilterMobileOpen" *ngIf="topService.showFilterBtn || showBackBtn"
  (swipedown)="hideFilter()">
  <app-item-filter-mobile *ngIf="itemsService.showFilterComponent" (onCloseClick)="hideFilter()">
  </app-item-filter-mobile>
</div>



<!-- live offer filter -->
<div class="btm-filter-wrap" 
  [class.__show]="buyerOffersService.isMobileFiltersOpen" *ngIf="buyerOffersService.isMobileFiltersOpen"
  (swipedown)="hideFilter()">
  <app-offers-filter-mobile *ngIf="true" (onCloseClick)="hideFilter()">
  </app-offers-filter-mobile>
</div>

<div class="btm-nav-wrap">
  <!-- For User pages -->
  <div class="btm-nav-top" *ngIf="showBackBtn">
    <div class="btm-nav-top-btns">
      <a class="btm-nav-top__btn" (click)="goBack()">
        <i class="icon">
          <img src="../../../assets/images/icons/24-back-black.svg" width="24" height="24" alt="Back" />
        </i>
        <span>Back</span>
      </a>
    </div>
  </div>
  <!-- For catalog -->
  <div class="btm-nav-top" *ngIf="topService.showFilterBtn">
    <!-- Back carret icon -->
    <div class="btm-nav-top-btns">
      <a class="btm-nav-top__btn" (click)="goBack()">
        <i class="icon">
          <img src="../../../assets/images/icons/24-back-black.svg" width="24" height="24" alt="Back" />
        </i>
        <span *ngIf="auctionsService.isDetailsPage">Back to Live Auctions</span>
      </a>
    </div>
    <!-- Past Purchases search -->
    <div class="btm-nav-top-search" *ngIf="itemsService.isPastPurchase && !userService.isGuest">
      <div class="btm-nav-top-search-wrap" [class.disabled]="false">
        <button class="btm-nav-top-search__btn" (click)="searchPastPurchase()">
          <i class="icon">
            <img src="../../../assets/images/icons/24-search-black.svg" width="24" height="24" alt="Order History" />
          </i>
        </button>
        <input type="search" placeholder="Search Order or Part #" (keyup)="isKeyEnter($event)"
          [(ngModel)]="topService.pastPurchasesSearchString">
      </div>
    </div>
    <!-- Filters icon -->
    <div class="btm-nav-top-btns __filter" *ngIf="!auctionsService.isDetailsPage">
      <a class="btm-nav-top__btn" (click)="showFilter()">
        <i class="icon">
          <img src="../../../assets/images/icons/24-filter.svg" width="24" height="24" alt="Filters" />
        </i>
        <span>Filters</span>
      </a>
    </div>
  </div>
  <div class="btm-nav">
    <ul class="nav">
      <li>
        <span class="btm-nav-link" (click)="!isCategoriesOpen?openCategories():hideCategories()">
          <img *ngIf="!isCategoriesOpen" src="../../../assets/images/icons/mobile/menu/24-categories.svg" width="24"
            height="24" alt="Categories">
          <img *ngIf="isCategoriesOpen" src="../../../assets/images/icons/mobile/menu/m-close-white.svg" width="24"
            height="24" alt="Close">
        </span>
      </li>
      <li>
        <span class="btm-nav-link">
          <img src="../../../assets/images/icons/header/outlets-white.svg" width="24" height="24" alt="Outlets"
            [routerLink]="[goToOutlets()]">
        </span>
      </li>
      <li>
        <span class="btm-nav-link" [routerLink]="['/user/offers']">
          <img src="assets/images/icons/rebranding/offers-header.svg" width="24" height="24"
                alt="My Offers" />
          <span class="offers-count" *ngIf="offersService.offersSummary?.unseenEventCount > 0">
            {{offersService.offersSummary?.unseenEventCount}}
          </span>
        </span>
      </li>
      <li>
        <span class="btm-nav-link">
          <img src="../../../assets/images/icons/header/cart-white.svg" width="32" height="32" alt="Cart"
            (click)="goToCart()">
          <span *ngIf="cartService.cartList && cartService.cartList.length > 0" class="cart-count"
            [class.jump]="cartService.isShownAddedModal">{{ cartService.totalUnits() }}</span>
        </span>
      </li>
      <li>
        <span class="btm-nav-link">
          <img src="../../../assets/images/icons/header/price-list-white.svg" width="24" height="24" alt="Price List"
            (click)="getPriceList()">
        </span>
      </li>
      <li>
        <span class="btm-nav-link" (click)="!isMainMenuOpen?openMainMenu():hideMainMenu()">
          <img *ngIf="!isMainMenuOpen" src="../../../assets/images/icons/mobile/menu/menu.svg" width="24" height="24"
            alt="Menu">
          <img *ngIf="isMainMenuOpen" src="../../../assets/images/icons/mobile/menu/m-close-white.svg" width="24"
            height="24" alt="Close">
        </span>
      </li>
    </ul>
  </div>
  <div class="nav-modal" [class._opened]="isMainMenuOpen">
    <i class="nav-modal__overlay" (swipedown)="hideMainMenu()"></i>
    <div class="nav-modal-menu">
      <div class="nav-modal-menu__close" (click)="hideMainMenu()"><i></i></div>
      <ul class="nav-modal-menu-list">
        <li>
          <a class="main-menu-link" [routerLink]="[navigateTo()]" (click)="hideMainMenu()">
            <i class="icon">
              <img src="../../../assets/images/icons/header/home-white.svg" width="24" height="24" alt="Home">
            </i>
            <span>Home</span>
          </a>
        </li>
        <li>
          <a class="main-menu-link" (click)="getPriceList();hideMainMenu()">
            <i class="icon">
              <img src="../../../assets/images/icons/header/price-list-white.svg" width="24" height="24"
                alt="Price List">
            </i>
            <span>Price List</span>
          </a>
        </li>
        <li *ngIf="!userService.isGuest">
          <a class="main-menu-link" [routerLink]="['/user/orders']" routerLinkActive="_active" (click)="hideMainMenu()">
            <i class="icon">
              <img src="../../../assets/images/icons/profile/order-history.svg" width="24" height="24"
                alt="Order History" />
            </i>
            <span>Order History</span>
          </a>
        </li>
        <li>
          <a class="main-menu-link" [routerLink]="goToPastPurchases()"
            (click)="itemsService.isPastPurchase=true;hideMainMenu()">
            <i class="icon">
              <img src="../../../assets/images/icons/header/past-purchase-white.svg" width="24" height="24"
                alt="Past Purchase">
            </i>
            <span>Items Purchased</span>
          </a>
        </li>
        <li *ngIf="!userService.isGuest">
          <a class="main-menu-link" [routerLink]="['/user/filters']" routerLinkActive="_active"
            (click)="hideMainMenu()">
            <i class="icon">
              <img src="../../../assets/images/icons/profile/24-saved-searches.svg" width="24" height="24" />
            </i>
            <span>Saved Searches</span>
          </a>
        </li>
        <li *ngIf="isBuyerPortal('creditCard')">
          <a class="main-menu-link" [routerLink]="['/user/credit_cards']" routerLinkActive="_active"
            (click)="hideMainMenu()">
            <i class="icon">
              <img src="../../../assets/images/icons/profile/credit-card.svg" width="24" height="24"
                alt="Credit Cards" />
            </i>
            <span>Credit Cards</span>
          </a>
        </li>
        <li *ngIf="!userService.isGuest">
          <a class="main-menu-link" [routerLink]="['/user/mobile_notifications']" routerLinkActive="_active"
            (click)="hideMainMenu()">
            <i class="icon">
              <img src="../../../assets/images/icons/profile/mobile-sms.svg" width="24" height="24"
                alt="Mobile | SMS" />
            </i>
            <span>Mobile | SMS</span>
          </a>
        </li>
        <li>
          <a class="main-menu-link" [routerLink]="[goToAuctions()]" routerLinkActive="_active" (click)="hideMainMenu()">
            <i class="icon">
              <img src="../../../assets/images/icons/header/green-dot.svg" width="24" height="24"
                alt="Live Auctions | My Bids" />
            </i>
            <span>Live Auctions</span>
          </a>
        </li>
        <li>
          <a class="main-menu-link" [routerLink]="['/user/offers']" routerLinkActive="_active"
            (click)="hideMainMenu()">
            <i class="icon">
              <img src="assets/images/icons/rebranding/offers-header.svg" width="24" height="24"
                alt="My Offers" />
            </i>
            <span>My Offers</span>
            <span class="_badge-counter" *ngIf="offersService.offersSummary?.unseenEventCount > 0">
              {{offersService.offersSummary?.unseenEventCount}}
            </span>
          </a>
        </li>
        <li *ngIf="!userService.isGuest">
          <a class="main-menu-link" [routerLink]="['/user/profile']" routerLinkActive="_active"
            (click)="hideMainMenu()">
            <i class="icon">
              <img src="../../../assets/images/icons/profile/profile.svg" width="24" height="24" alt="User Profile" />
            </i>
            <span>User Profile</span>
          </a>
        </li>
        <li *ngIf="!userService.isGuest">
          <a class="main-menu-link" [routerLink]="['/user/support']" routerLinkActive="_active"
            (click)="hideMainMenu()">
            <i class="icon">
              <img src="../../../assets/images/icons/profile/support.svg" width="24" height="24" alt="Support Team" />
            </i>
            <span>Support Team</span>
          </a>
        </li>
        <ng-container *ngIf="userService.isGuest">
          <li class="divider__guest"></li>
          <li>
            <a class="main-menu-link" (click)="navigateToCorporate('corphome')">
              <span>Our Company</span>
            </a>
          </li>
          <li>
            <a class="main-menu-link" (click)="navigateToCorporate('products')">
              <span>Products</span>
            </a>
          </li>
          <li>
            <a class="main-menu-link" (click)="navigateToCorporate('sell')">
              <span>Sell On HUBX</span>
            </a>
          </li>
          <li>
            <a class="main-menu-link" (click)="navigateToCorporate('aboutUs')">
              <span>About Us</span>
            </a>
          </li>
          <li>
            <a class="main-menu-link" (click)="navigateToCorporate('faq')">
              <span>FAQ</span>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <div class="nav-modal" [class._opened]="isCategoriesOpen">
    <i class="nav-modal__overlay"></i>
    <div class="nav-modal-menu">
      <div class="nav-modal-menu__close" (click)="hideCategories()"><i></i></div>
      <ul class="nav-modal-menu-list categories-menu">
        <!-- Todays Deals Top Navigation -->
        <li *ngIf="topService.getTopFilterByName(environment.currentDeal.displayLabel) as todayDeals">
          <a class="categories-menu-link" [routerLink]="[topService.getURLByTopFilter(todayDeals)]"
            (click)="hideCategories()">
            <i class="icon">
              <img src="../../../assets/images/icons/item-filter/doorbuster-24x24.svg" width="24" height="24"
                alt="environment.currentDeal.displayLabel">
            </i>
            <span class="label">{{ todayDeals.navName }}</span>
            <span class="count">{{ todayDeals.count }}</span>
          </a>
        </li>

        <!-- Price Drop Top Navigation -->
        <li *ngIf="topService.getTopFilterByName(topFiltersEnum.priceDrop) as priceDrop">
          <a class="categories-menu-link" [routerLink]="[topService.getURLByTopFilter(priceDrop)]"
            (click)="hideCategories()">
            <i class="icon">
              <img src="../../../assets/images/icons/mobile/menu/24-price-drop-white.svg" width="24" height="24"
                alt="Price Drop">
            </i>
            <span class="label">{{ priceDrop.navName }}</span>
            <span class="count">{{ priceDrop.count }}</span>
          </a>
        </li>

        <!-- Just Launched Top Navigation -->
        <li *ngIf="topService.getTopFilterByName(topFiltersEnum.justLaunched) as justLaunched">
          <a class="categories-menu-link" [routerLink]="[topService.getURLByTopFilter(justLaunched)]"
            (click)="hideCategories()">
            <i class="icon">
              <img src="../../../assets/images/icons/mobile/menu/24-just-lauched-white.svg" width="24" height="24"
                alt="Just Launched">
            </i>
            <span class="label">{{ justLaunched.navName }}</span>
            <span class="count">{{ justLaunched.count }}</span>
          </a>
        </li>
        <li *ngIf="sharedService.justSoldValues.totalLineItems">
          <a class="categories-menu-link"
            (click)="!topService.loading?itemsService.selectJustSold():'';hideCategories()"
            [routerLink]="[gotoJustSold()]">
            <i class="icon">
              <img src="../../../assets/images/icons/mobile/menu/24-just-sold.svg" width="24" height="24"
                alt="Just Sold">
            </i>
            <span class="label">Just Sold</span>
            <span [ngClass]="{'flash': justSoldCount }" class="count animated">
              {{ sharedService.justSoldValues.totalLineItems ? sharedService.justSoldValues.totalLineItems : 0 }}</span>
          </a>
        </li>
        <li *ngIf="true">
          <a class="categories-menu-link"
            (click)="!topService.loading?itemsService.selectJustSold():'';hideCategories()"
            [routerLink]="['/buyer/live_offers']">
            <i class="icon">
              <img src="assets/images/icons/rebranding/offers-header.svg" width="24" height="24"
                alt="Live Offers">
            </i>
            <span class="label">Live Offers</span>
            <span class="count">{{ liveOffersCounterSignal() }}</span>
          </a>
        </li>
        <!--<bottom-nav-mobile-ext-dropdown></bottom-nav-mobile-ext-dropdown>-->
        <li *ngFor="let category of itemsService.leftNavigationsObservable; let i = index"
          [class._opened]="selectedCategoryId === i">
          <span class="categories-menu-link" (click)="parentClicked(category, i)">
            <i class="icon">
              <img [src]="imageBaseUrl + category.iconUri | whiteLogos" width="24" height="24" alt="Close">
            </i>
            <span class="label">{{ category.navName }}</span>
            <span class="count">{{ category.count }}</span>
          </span>
          <ul class="categories-menu-sub" *ngFor="let subnav of category.navChilds"
            [class.hide]="subnav.navName==='New'||subnav.navName==='Refurbished'">
            <li>
              <a class="categories-menu-link" (click)="childNav(subnav);hideCategories();">
                <span class="label">{{ subnav.navName }}</span>
                <span class="count">{{ subnav.count }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="price-list-loader-modal-wrap" [class.__show]="topService.isExporting && !topService.isDowloadPriceUrl">
  <div class="price-list-loader-modal">
    <div class="price-list-loader-modal__icon">
      <img src="../../../assets/images/icons/header/price-list-white.svg" width="32" height="32" alt="Price List" />
    </div>
    <div class="price-list-loader-modal__title">
      Preparing <br />a Price List
    </div>
    <span class="price-list-loader-modal__txt">for Download</span>
    <div class="price-list-loader-modal__img">
      <img src="../../../assets/images/spinner-blue.gif" width="64" height="64" alt="loading" />
    </div>
  </div>

  <span class="price-list-loader-modal-wrap__close" (click)="cancelDownload()">

  </span>
</div>

<!-- Add To Cart Dialog -->
<app-add-to-cart-modal *ngIf="cartService.isShownAddedModal"></app-add-to-cart-modal>