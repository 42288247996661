import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { TopService } from '../../../shared/services/top.service';
import { ItemsService } from '../../../shared/services/items.service';
import { OutletsService } from '../outlets.service';
import { Router } from '@angular/router';
import { ItemFilterService } from '../../../shared/item-filter/item-filter.service';
import { HttpClient } from '@angular/common/http';
import { Location, NgIf } from '@angular/common';
import { IDropdown } from '../../../vendor/interface/IDropdown';
import { SharedService } from '../../../shared/shared.service';
import * as _ from 'lodash';

import { SharedSource } from '../../../core/shared-source';
import { PubSubService } from '../../../core/pubsub.service';
import { of, Subscription } from 'rxjs';
import { ITopNav } from '../../interfaces/ITopNav';
import { UserService } from '../../../user/user.service';
import { SessionService } from '../../../services/session.service';
import { environment } from '../../../../environments/environment';
import { BottomNavMobileComponent } from '../../../shared/bottom-nav-mobile/bottom-nav-mobile.component';
import { ItemsListComponent } from '../../catalog/items-list/items-list.component';
import { FormsModule } from '@angular/forms';
import { DropdownComponent } from '../../../shared/dropdown/dropdown.component';
import { CreditCardNotificationComponent } from '../../credit-cards/credit-card-notification/credit-card-notification.component';

@Component({
    selector: 'app-outlet-item',
    templateUrl: './outlet-item.component.html',
    styleUrls: ['./outlet-item.component.scss'],
    standalone: true,
    imports: [NgIf, CreditCardNotificationComponent, DropdownComponent, FormsModule, ItemsListComponent, BottomNavMobileComponent]
})
export class OutletItemComponent implements OnInit, OnDestroy {
  imageBaseUrl = environment.imageBaseUrl;
  outletsOptions = [];
  selectedDdOutlet;
  subscription: Subscription;
  selectedOutletName;

  @ViewChild('wrapper', { read: ElementRef, static: true }) public wrapper: ElementRef<any>;

  constructor(public topService: TopService,
              public itemsService: ItemsService,
              public itemFilterService: ItemFilterService,
              private _router: Router,
              public outletsService: OutletsService,
              public sharedService: SharedService,
              private userService: UserService,
              private http: HttpClient,
              private location: Location,
              private pubSubService: PubSubService,
              public sessionService: SessionService) { 
                this.itemFilterService.isOutletsSinglePage = true;
              }

  ngOnInit() {
    this.topService.trendingNavVisible = true;
    this.sharedService.isCorpSite = false;

    this.topService.searchString = '';
    this.itemFilterService.selectedSearch = '';
    this.itemsService.currentNavigation = undefined;

    // prevent showhing items from previuos request
    this.itemsService.itemsReady = false;

    // to avoid keeping the "Save New Search" button hidden after navigating to a campaign or search item
    this.itemsService.isCampainUrl = false;
    this.topService.searchString = '';

    this.topService.loading = true;
    this.itemFilterService.isSecondLevelExpanded = true;


    const cleanUrl = this._router.url.replace(/\/guest/, '');

    const currentOutlet = cleanUrl.split('/');
    const outletName = currentOutlet[2].replace(/\_/g, ' ');
    // you are in a top category e.g todays_deals
    if (currentOutlet.length === 4) {
      this.outletsService.topcatNavName = currentOutlet[3];
    }

    if(this.itemFilterService.allManufacturers?.length && !this.outletsService.isCalledFromMenu) {
      this.getDdManufacturers();
      this.selectCurrentManufacturer(outletName);
    }

    this.subscription = this.pubSubService.sharedSubject.subscribe(
      mySharedValues => {
        if (mySharedValues.name === SharedSource.topFiltersOutletChanged) {
          let topNav;
          switch (this.outletsService.topcatNavName) {
            case environment.currentDeal.url: topNav = mySharedValues.data.find(n => n.navName === 'Todays Deals'); break;
            case 'price_drop': topNav = mySharedValues.data.find(n => n.navName === 'Price Drop'); break;
            case 'just_launched': topNav = mySharedValues.data.find(n => n.navName === 'Just Launched'); break;
          }
          this.itemsService.paginationInfo = null;
          if (this.outletsService.topcatNavName && topNav) {
            this.itemFilterService.selectedZeroLevel = topNav;
            this.itemsService.selectAttrAndGetItems(topNav);
          }
        }
        if (mySharedValues.name === SharedSource.navigationReady) {
          // all manufacturers is ready here too
          this.getDdManufacturers();
          this.selectCurrentManufacturer(outletName);
        }
        if (mySharedValues.name === SharedSource.manufacturersDataChanged) {
          this.getDdManufacturers();
        }
      }
    );
    this.selectedOutletName = this.selectedDdOutlet ? this.selectedDdOutlet.name : 'More Outlets';
    this.outletsService.isCalledFromMenu = false;
  }

  selectCurrentManufacturer(outletName: string) {
    if (!this.itemFilterService.selectedManufacturer ||
      (this.itemFilterService.selectedManufacturer &&
        this.itemFilterService.selectedManufacturer.name.toLowerCase() !== outletName.toLowerCase())) {

      // always call all manufacturers cuz manufacturersData may have
      // manus from previous filter and not all

      const manus = _.cloneDeep(this.itemFilterService.allManufacturers);
      if (manus && manus.length) {
        this.itemsService.initializeManufacturerData([...manus]);
        const selectedOutlet = manus.find(m => m.name.toLowerCase() === outletName.toLowerCase());
        this.outletsService.manufacturersData(selectedOutlet);
      }
    }
  }

  getDdManufacturers() {
    const allManufacturers$ = (this.itemFilterService.allManufacturers?.length) ?
      of(this.itemFilterService.allManufacturers) : this.itemsService.getManufactures();

    allManufacturers$.subscribe(manufacturers => {
      const outletOptions = [];

      manufacturers.forEach(manu => {
        outletOptions.push({
          'id': manu.id,
          'name': manu.name,
          'count': manu.count,
          'heroImageUrl': manu.heroImageUrl,
          'blackLogoUrl': manu.blackLogoUrl,
          'logoUrl': manu.logoUrl
        });
        this.outletsOptions = outletOptions;
      });
      this.outletsOptions = _.orderBy(this.outletsOptions, ['name'], ['asc']);
    });
  }

  ngOnDestroy() {
    this.outletsService.topcatNavName = undefined;
    this.itemFilterService.isOutletsSinglePage = false;
    this.topService.trendingNavVisible = false;
    this.itemFilterService.clearAll();

    this.itemsService.itemsRequest.categoryId = null;

    this.outletsService.selectedOutlet = null;
    // if theres a request in the air but user leaves the url
    // cancels the request
    if (!this.itemsService.itemsReady &&
      this.itemsService.itemsSubscription && !this.topService.searchString) {
      this.itemsService.itemsSubscription.unsubscribe();
      this.itemsService.itemsSubscription = undefined;
    }
  }

  selectOutlet(outlet) {
    this.selectedDdOutlet = outlet;
    this.itemsService.clearAllFiltersAndNav();
    if (this.itemFilterService.allManufacturers) {
      this.itemsService.initializeManufacturerData([...this.itemFilterService.allManufacturers]);
    }
    this.itemFilterService.selectedManufacturer = this.selectedDdOutlet;
    this.itemsService.selectedUrlFilterId = null;
    this.itemsService.selectedTopAttribute = '';
    this.itemsService.skipManufacturers = true;
    this.itemsService.setSelectedManufacturer(this.selectedDdOutlet.id, true, true);
    this.itemsService.getManufacturersBreakDownById(this.selectedDdOutlet.id);
    this.outletsService.selectedOutlet = this.selectedDdOutlet;
    const transformedName = this.selectedDdOutlet.name.replace(/\s/g, '_').toLowerCase();
    this._router.navigate([`${this.userService.getUrlByRole(true)}/outlets/`, transformedName]);
  }

  goBack() {
    this.location.back();
  }

  isCreditCardBanner(): boolean {
    return this.isBuyerPortal() && this.userService.isCreditCardAllowed && this.userService.isCreditCardListEmpty === 0 && this.sessionService.closeCreditCardBanner === 'false';
  }

  isBuyerPortal(): boolean {
    return ['BUYER'].includes(sessionStorage.getItem('user_role'));
  }

  closeCCardNotification() {
    this.sessionService.closeCreditCardBanner = 'true';
  }

  getDefaultTitleForManufacturer(): string {
    this.selectedOutletName = this.selectedDdOutlet ? this.selectedDdOutlet.name : 'More Outlets';
    return this.selectedOutletName;
  }

}
