<div class="modal" [class.mobile]="topService.isMobile">
  <div class="modal-wrap animated" [class.zoomOut]="false" [class.zoomIn]="true">
    <div class="modal-content">
      <div *ngIf="topService.loading" class="loader"></div>
      <i class="modal-content__close" (click)="closeModal.emit(true);"></i>
      <div class="step-1" *ngIf="!isConfirm && !phoneIsVerified">
        <h3 class="modal-content__header">Hey {{ userService.userProfile?.firstName }}!</h3>
        <p>We launched a new feature which allows you to receive your HUBX notifications via SMS or WhatsApp.</p>
        <p>Please provide your number below.</p>
        <span>(You will only receive SMS or WhatsApp from notifications you selected in your HUBX account for price drops, more inventory or back in stock items)</span>
        <form [formGroup]="fgPhoneNumb">
          <div class="form">
              <span class="label-select"><strong>Select:</strong></span>
              <div class="form__radio">
                <label class="radio-btn">
                  <input type="radio" id="sms_input" formControlName="msgType" [value]="1">
                  <span class="radio-btn__label">SMS/Text</span>
                </label>
                <label class="radio-btn">
                  <input type="radio" id="whatsapp_input" formControlName="msgType" [value]="2">
                  <span class="radio-btn__label">WhatsApp</span>
                </label>
              </div>
              <div class="form__input">
                <div class="notifications-dropdown" (clickOutside)="hideDropdown($event)">
                  <div class="notifications-dropdown__selected" (click)="toggleDropdown()">
                    <img *ngIf="selectedCountry" 
                          class="icon" 
                          width="24" 
                          height="24" 
                          [src]="imageBaseUrl + '/' + selectedCountry?.flagUrl" />
                    <span>{{(selectedCountry) ? '+' + selectedCountry.code : 'Select Code'}}</span>
                    <i>
                      <img src="../../../assets/images/vendor-icons/icon-dropdown-black.svg">
                    </i>
                  </div>
                  <div class="notifications-dropdown__wrapper" [class.active]="isDropdownToggled">
                    <div class="notifications-dropdown__select" *ngFor="let country of countries" [class.active]="country.id===selectedCountry?.id" (click)="selectCountry(country)">
                      <img class="icon" 
                      width="24" 
                      height="24" 
                      [src]="imageBaseUrl + '/' + country?.flagUrl" />                 
                      <span class="code">+{{country.code}}</span>
                      <span>{{country.countryName}}</span>
                    </div>
                  </div>
                </div>
                <input type="tel" placeholder="Enter number here" formControlName="phoneNumb" mask="000 000 0000 0*" [validation]="false">
              </div>
            </div>
        </form>
        <div class="modal-content-btns">
          <button 
            type="button" 
            class="button"
            [disabled]="!fgPhoneNumb.valid || loading || !selectedCountry || !isSMSAllowed"
            (click)="savePhoneNumber()">
            <!-- <div class="price-list-loader" *ngIf="topService.loading"></div> -->
          Confirm</button>
          <!-- <a role="button" class="button-animated"
            [class.disabled]="!fgPhoneNumb.valid || topService.loading || !selectedCountry"
            (click)="savePhoneNumber()"
            (mouseenter)="hoverBtn($event,true)"
            (mouseleave)="hoverBtn($event,false)"
            >
              <span class="button-animated__wrap">
                <div class="button-animated__text">Confirm</div>
                <svg width="166px" height="44px" viewBox="-1 -1 168 46" class="button-animated__svg"><path d="M21.046729,2 C9.97522627,2 1,10.954305 1,22 C1,33.045695 9.97522627,42 21.046729,42 C21.046729,42 144.953271,42 144.953271,42 C156.024773,42 165,33.045695 165,22 C165,10.954305 156.024773,2 144.953271,2 C144.953271,2 21.046729,2 21.046729,2 Z" class="button-animated__path"></path></svg>
              </span>
            </a> -->
        </div>
      </div>
      <div class="step-2" *ngIf="isConfirm">
        <h3 class="modal-content__header">We sent a 4 digit confirmation code to your phone or WhatsApp. <br/>Please confirm.</h3>
        <form [formGroup]="fgConfirm">
          <div class="verification-input-list">
              <div class="verification-input">
                <input type="tel" placeholder="_" maxlength="1" formControlName="n1" mask="0"#n1>
              </div>
              <div class="verification-input">
                <input type="tel" placeholder="_" maxlength="1" formControlName="n2" mask="0"#n2>
              </div>
              <div class="verification-input">
                <input type="tel" placeholder="_" maxlength="1" formControlName="n3" mask="0"#n3>
              </div>
              <div class="verification-input">
                <input type="tel" placeholder="_" maxlength="1" formControlName="n4" mask="0"#n4>
              </div>
            </div>
            <p class="cancel-error" *ngIf="showError">Invalid code, please try again.</p>
        </form>
        <span class="verification-resend" (click)="resendVerificationCode()">Click here to resend</span>
        <div class="modal-content-btns">
          <button type="button" class="button-transparent back" (click)="isConfirm = false;">Back</button>
          <!-- <button [class.hide]="true" type="button" class="button" (click)="confirmPhoneNumber()" [disabled]="!fgConfirm.valid || topService.loading">
            <div class="price-list-loader" *ngIf="topService.loading"></div>
            Confirm</button> -->
        </div>
      </div>
      <div class="step-3" *ngIf="phoneIsVerified">
        <h3 class="modal-content__header">Thank you {{ userService.userProfile?.firstName }}!</h3>
        <p>You’re all set. You will now receive HUBX notifications to your phone. </p>
        <div class="modal-content-btns">
          <button type="button" class="button" (click)="closeModal.emit(true)">Got It</button> 
          <!-- <a role="button" class="button-animated"
            (click)="closeModal.emit(true)"
            (mouseenter)="hoverBtn($event,true)"
            (mouseleave)="hoverBtn($event,false)"
            >
              <span class="button-animated__wrap">
                <div class="button-animated__text">Got It</div>
                <svg width="166px" height="44px" viewBox="-1 -1 168 46" class="button-animated__svg"><path d="M21.046729,2 C9.97522627,2 1,10.954305 1,22 C1,33.045695 9.97522627,42 21.046729,42 C21.046729,42 144.953271,42 144.953271,42 C156.024773,42 165,33.045695 165,22 C165,10.954305 156.024773,2 144.953271,2 C144.953271,2 21.046729,2 21.046729,2 Z" class="button-animated__path"></path></svg>
              </span>
            </a> -->
        </div>
      </div>
    </div>
  </div>
</div>
<app-error-modal
  [title]="'Warning'" [isError]="showSMSPopup" [bodyText]="'SMS/Text is not available in your country. Please select WhatsApp instead.'" (onConfirmError)="onSmsPopupClose()">
</app-error-modal>