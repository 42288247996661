import { VendorService } from './../../../vendor/vendor.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as models from './../../../shared/interfaces/model';
import { UntypedFormArray, FormBuilder, FormGroup, Validators, FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OfferService } from '../offer.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OfferStatusCancelationWarning } from './../../../shared/enums/cancelation-warning-offer-status';
import { CustomOfferCurrencyPipe } from '../../../shared/pipes/offer-currency.pipe';
import { ConfirmationPopupComponent } from '../../../shared/confirmation-popup/confirmation-popup.component';
import { NgIf, NgFor } from '@angular/common';
import { environment } from './../../../../environments/environment';

@Component({
    selector: 'app-offer-cancellation-warning',
    templateUrl: './offer-cancellation-warning.component.html',
    styleUrls: ['./offer-cancellation-warning.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        NgFor,
        ConfirmationPopupComponent,
        CustomOfferCurrencyPipe,
    ],
})
export class OfferCancellationWarningComponent implements OnInit {
  @Output() closeCancellationWarningModal = new EventEmitter();
  @Output() refreshCancellationWarningModal = new EventEmitter();
  @Output()
  updateAfterCancelationWarning: EventEmitter<models.PendingOfferByAction> =
    new EventEmitter<models.PendingOfferByAction>();
  @Input()pendingOffers: models.ItemWithPendingOffers[];
  @Input() showRefreshMsg: boolean;
  @Input() totalPendingOffers: number;
  @Input() disableReviewOffersButton = false;
  imageBaseUrl = environment.imageBaseUrl;
  OfferStatusCancelationWarning = OfferStatusCancelationWarning;
  animateIn: boolean = false;
  animateOut: boolean = false;
  pendingOffersForm: FormGroup;
  offersForCancel = 0;
  offersAction = models.PendingOfferAction;

  private destroy$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    public offerService: OfferService,
    private vendorService: VendorService
  ) {}

  ngOnInit(): void {
    this.animateIn = true;
    this.initForm();
    this.checkForOffersUpdates();
  }

  close() {
    this.animateIn = false;
    this.animateOut = true;
    setTimeout(() => {
      this.animateOut = false;
      this.closeCancellationWarningModal.emit();
    }, 200);
  }

  update() {
    if (
      !this.offerService.offersCancellationPopupSettings.show &&
      this.offersForCancel > 0
    ) {
      this.offerService.offersCancellationPopupSettings.headerText = `You are about to cancel ${this.offersForCancel} offers. Please confirm the process`;
      this.offerService.offersCancellationPopupSettings.show = true;
      return;
    }

    const pendingItemOffersByAction = {
      pendingOffersToKeep: this.pendingOffers.filter(
        (po) => po.offersAction === models.PendingOfferAction.KEEP
      ),
      pendingOffersToCancel: this.pendingOffers.filter(
        (po) => po.offersAction === models.PendingOfferAction.CANCEL
      ),
    } as models.PendingOfferByAction;
    this.updateAfterCancelationWarning.emit(pendingItemOffersByAction);
    this.close();
  }

  ngOnDestroy() {
    this.destroy$.next('');
    this.destroy$.unsubscribe();
    this.offerService.loadingOfferCancelationData = false;
  }

  private initForm(): void {
    this.pendingOffersForm = this.formBuilder.group({
      offers: this.formBuilder.array([]),
    });

    const controlArray = this.pendingOffersForm.get('offers') as UntypedFormArray;

    this.pendingOffers.forEach(({ offersAction }, index) => {
      controlArray.push(
        this.formBuilder.group({
          id: [index],
          selectedOption: [offersAction, Validators.required],
        })
      );
    });
  }

 checkForOffersUpdates(): void {
    this.pendingOffersForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ offers }) => {
        this.offersForCancel = 0;
        offers.forEach(({ id, selectedOption }) => {
          if (
            [
              this.offersAction.KEEP,
              this.offersAction.CANCEL,
            ].includes(selectedOption)
          ) {
            this.pendingOffers[id].offersAction = selectedOption;
          }
          if (selectedOption === this.offersAction.CANCEL) {
            this.offersForCancel += this.pendingOffers[id].offers.length;
          }
        });
      });
  }

  refresh() {
    this.refreshCancellationWarningModal.emit();
  }

  addItemOffersToFormArray(pendingOffer: models.ItemWithPendingOffers): void {
    const controlArray = this.pendingOffersForm.get('offers') as FormArray;
    controlArray.push(
      this.formBuilder.group({
        id: [controlArray.length],
        selectedOption: [pendingOffer.offersAction, Validators.required],
      })
    );
  }

}
