<div *ngIf="openItemDetails" class="item-details-pop" 
[class.offer-active]="offerService.showMakeOfferPopup(item)">
  <div class="add-to-wrap animated" [class.zoomIn]="openItemDetails" [class.zoomOut]="animateOut && !loading">
    <div class="modal-content" (clickOutside)="onClickedOutside($event)" [clickOutsideEvents]="'click'"
    [exclude]="'.add-to-wrap,.item,.reset-pwd-modal,.item-notification-tooltip-wrapper, .restriction-notice-modal'"
    [excludeBeforeClick]="true">
      <div class="row modal-body">

        <div class="col left item-attributes-detail-modal">
          <div *ngFor="let attribute of getVisibleAttributes()">
            <div *ngIf="attribute.value" class="item-attributes-img">
              <img [ngClass]="{'restr': attribute.label === 'Restrictions', 'p-drop': attribute.label === 'Price Drop'}"
              width="24px" height="24px" [src]="getAttributeUri(attribute.label)">
            </div>
            <div *ngIf="attribute.value">
              <span class="label">{{attribute.label |  currentDeal}}</span>
            </div>
            <div>
              <span *ngIf="attribute.value !== 'Yes' && attribute.value !== 'No'" class="value">{{attribute.value}}</span>
            </div>
            <div *ngIf="attribute.label === 'Restrictions' && attribute.value === 'Yes'">
              <span class="value">{{attribute.description}} </span>
            </div>
          </div>
          <div *ngIf="item.leadTimeDays">
            <div class="item-attributes-img">
              <img src="assets/images/new-item-details/eta.svg" alt="ETA" width="24" height="24" />
            </div>
            <div>
              <span class="label">ETA</span>
            </div>
            <div>
              <span class="value">{{ item.leadTimeDays }} Days</span>
            </div>
          </div>
          <div class="more-info-d" *ngIf="item.comments[0]">
            <div class="item-attributes-img">
              <img src="assets/images/icons/info-white.svg" alt="more-info" width="24" height="24">
            </div>
            <div>
              <span class="label">More Info</span>
            </div>
            <div>
              <div class="value">{{ item.comments[0].comment }}</div>
            </div>
          </div>
        </div>


        <div class="col right">
          <div class="right-top">
            <div class="item__logo logo">
              <div *ngIf="item.manufacturerLogoUrl" [ngStyle]="{'background-image': 'url(' + item.manufacturerLogoUrl + ')'}"></div>
              <div *ngIf="!item.manufacturerLogoUrl" [style.background-image]="'url(' + imageBaseUrl + '/assets/images/item-placeholder.svg)'">
              </div>
            </div>
            <div class="exw">
              <div class="exw-label">EXW</div>
              <div class="exw-value">{{ item.exwPoint }}</div>
            </div>
            <div class="right-top-icons">
              <div *ngIf="item.gradingGuideUrl" (click)="openGradingGuide($event)" class="grading"></div>
                <div class="notification-icon" *ngIf="item.inventory.availableToSell>0"
                  [class.active]="item?.notificationInfoObj?.moreInventoryInitialValue || item?.notificationInfoObj?.priceDropInitialValue">
                  <svg (click)="userService.isGuest?showGuestLogin():''"
                  (mouseleave)="topService.notificationTooltipHovered = false"
                  (mouseenter)="userService.isGuest?'':openNotificationTooltip($event)" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <linearGradient id="a" x1="74.588658%" x2="16.243437%" y1="17.361718%" y2="82.294216%"><stop offset="0" stop-color="#fe563b"/><stop offset="1" stop-color="#d8214d"/></linearGradient>
                    <path d="m13.78 19.7181605c0 1.0499261-.8554974 2.2818395-1.9 2.2818395h-.76c-1.0445026 0-1.9-1.2319134-1.9-2.2818395zm-7.3234555-11.87516484c.3382199-6.08957172 3.43591623-5.84158916 5.0434555-5.84158916l.0859603-.00019339c1.6294207-.0065559 4.625209-.1408844 4.9574952 5.84578227.2805236 5.08564232 4.0208377 8.28541732 4.0208377 8.28541732.2595818.2489958.4153299.5878449.4357068.9479333v.9199353c-.0032625.316075-.2585269.570883-.5729843.5719598v.0039997h-17.85403141c-.31599725-.0010972-.57189275-.2583214-.57298429-.5759595v-.923935c.02017979-.3601379.17595983-.6990563.43570681-.9479334 0 0 3.74031413-3.1937753 4.02083769-8.28541724z" fill="url(#a)"/>
                  </svg>
                </div>
            </div>
          </div>
          <div class="description">
            <div class="desc" [class._has-grading-guide]="item.gradingGuideUrl">
            {{item.description}} -
            <span class="copy-number" (mouseover)="itemsService.copyState()" (click)="itemsService.copyText($event, item.partNumber ? item.partNumber :item.mpn)">
              {{item.partNumber ? item.partNumber :item.mpn}}
            <span ngModel="copied" ngDefaultControl>{{ itemsService.copied }}</span>
            </span>
          </div>
          </div>

          <div [ngClass]="{'adjust-float':!item.gradingGuideUrl}" class="row moq">
            <div class="col moq align-self-center">
              <div class="minqty" *ngIf="item.moq>1">
                <div class="wrapper">
                  <div [ngClass]="{'displayItemMoq': showItemMOQ}" class="itemMoqTooltip listing-moq-tt">Minimum Order Quantity</div>
                  <span (mouseenter)="itemMOQTooltip()" (mouseleave)="itemMOQTooltip()">MOQ</span>
                </div>
                {{item.moq}}
              </div>
              <div class="maxqty" *ngIf="item.mxq > 0">
                <div class="wrapper">
                  <div [ngClass]="{'displayItemMxq': showItemMXQ}" class="itemMxqTooltip listing-mxq-tt">Maximum Quantity</div>
                  <span (mouseenter)="itemMXQTooltip(1)" (mouseleave)="itemMXQTooltip(1)">MXQ</span>
                </div>
                {{ item.mxq }}
              </div>
              <div class="item-moq">
                <ng-container *ngFor="let price of item.prices">
                  <div class="item__count--wrapper">
                    <div class="moq-wrap">
                      <span class="price-qty">{{price.fromQty}}+</span>
                      <span class="unit-price">{{price.unitPrice | CustomCurrency}}</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div> 

        <div class="modal-offer" *ngIf="offerService.showMakeOfferPopup(item)">
          <div class="modal-offer-label">
            <span>or</span>
          </div>
          <app-make-offer  [item]="item" (notificationTooltipEmitter)="showNotificationInOffer($event)"></app-make-offer>
        </div>  

      </div>
      <div class="row modal-footer">
        <div class="modal-footer-right">
          <div class="item-price">
            <div class="item-price-label">Price</div>
            <div class="item-price-value">{{ (item.baseUnitPrice ? item.baseUnitPrice  : item.unitPrice) | CustomCurrency}}</div>
          </div>

          <div class="available">
            <div>
              <div class="available-label">Available</div>
              <span *ngIf="!itemsService.showInTransitTitle(item, sessionService.userRole) && !itemsService.showInStockTitle(item, sessionService.userRole)" class="unit-price-avail available-item"
              [class.sold]="item.inventory.availableToSell<1">
                <span class="available-item-value">
                  {{ item.inventory.availableToSell>0?itemsService.showInStockValue(item, sessionService.userRole):"Sold Out" }}
                </span>
              </span>
              <span *ngIf="itemsService.showInStockTitle(item, sessionService.userRole)" class="available-item _stock">
                <span class="available-item-label">In Stock</span>
                <span class="available-item-value">{{ itemsService.showInStockValue(item, sessionService.userRole) }}</span>
              </span>
              <span *ngIf="itemsService.showInTransitTitle(item, sessionService.userRole)" class="available-item _transit">
                <span class="available-item-label">In Transit</span>
                <span class="available-item-value">{{itemsService.showInTransitValue(item, sessionService.userRole)}}</span>
              </span>
            </div>
          </div>


          <div class="add-to-controls" *ngIf="sessionService.userRole !== 'GUEST'">
            <div class="wrapper" *ngIf='sessionService.userCanBuy' [class.hide]='!item.added && item.inventory.availableToSell<1'>
              <div *ngIf="item.mxq > 0" [ngClass]="{'displayItemMxq': showInputMXQ}" class="itemMxqTooltip listing-mxq-tt">Maximum Quantity {{ item.mxq }}</div>
              <input type="number" [(ngModel)]="item.qty" (keyup)="searchSubject.next(item)"
                appOnlyDigits (mouseenter)="itemMXQTooltip(2)" (mouseleave)="itemMXQTooltip(2)"
                [disabled]="topService.loading || item.added || !item.added && item.inventory.availableToSell<1"
                [class.disabled]="item.added" #qty [ngClass]="{'item-disabled': item.added || item.inventory.availableToSell<1}" />
            </div>
            <div class="add-to-cart" *ngIf='sessionService.userCanBuy' style="display: flex;">
              <a *ngIf='!item.added && item.inventory.availableToSell>0' (click)="!topService.loading?addToCartBuyer():''"
                class="item-to-cart">
                <svg *ngIf="!item.loading" width="28px" height="28px" viewBox="0 0 28 23" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="add-to-cart" fill-rule="nonzero">
                      <path d="M21,4 L20.349795,6.13178125 L18.8204409,6.13178125 L15.8016246,16.16 L4.21811963,16.16 L0,6.05183945 L15.1377483,6.05183945 L12.7901031,13.9780153 L14.3265479,13.9780153 L17.3285301,4 L21,4 Z M12.0799764,17.44 C11.3977189,17.44 10.8446823,18.0130512 10.8446823,18.72 C10.8446823,19.4269488 11.3977189,20 12.0799764,20 C12.762234,20 13.3152705,19.4269488 13.3152705,18.72 C13.3152705,18.0130512 12.762188,17.44 12.0799764,17.44 Z M7.75629904,17.44 C7.07406685,17.44 6.52105086,18.0130512 6.52105086,18.72 C6.52105086,19.4269488 7.07406685,20 7.75629904,20 C8.43857718,20 8.9916391,19.4269488 8.9916391,18.72 C8.9916391,18.0130512 8.43857718,17.44 7.75629904,17.44 Z"
                        id="shopping-cart-icon" transform="translate(10.500000, 12.000000) scale(-1, 1) translate(-10.500000, -12.000000) "></path>
                    </g>
                  </g>
                </svg>
                <span *ngIf="item.loading" class="cart-loader"></span>
                <span *ngIf="!item.beingEdited&&!item.loading">Add to Cart</span>
                <span *ngIf="!item.beingEdited&&item.loading">Adding...</span>
                <span *ngIf="item.beingEdited&&!item.loading">Update Cart</span>
                <span *ngIf="item.beingEdited&&item.loading">Updating...</span>
              </a>

              <div class="add-waitlist" *ngIf='item.inventory.availableToSell<1' (click)="addToWaitlist($event)"
              [class.added]="item.notificationInfoObj.waitlist">
                <i class="_icon"></i>
                <span *ngIf="!item.notificationInfoObj.waitlist">Add to Waitlist</span>
                <span *ngIf="item.notificationInfoObj.waitlist">Waitlisted</span>
                <div class="item-waitlist-tooltip-wrapper">
                  <div class="item-waitlist-tooltip">
                    <div class="col">
                      <div class="item-waitlist-tooltip-img">
                        <img *ngIf="!item.notificationInfoObj.waitlist" src="assets/images/icons/popup-bell.svg" width="60" height="60" alt="bell">
                        <img *ngIf="item.notificationInfoObj.waitlist" src="assets/images/icons/popup-bell-added.svg" width="60" height="60" alt="bell">
                      </div>
                       <div class="item-waitlist-tooltip-header">
                        <span *ngIf="!item.notificationInfoObj.waitlist">This item is sold out!</span>
                        <span *ngIf="item.notificationInfoObj.waitlist">Nice!</span>
                      </div>
                      <div class="item-waitlist-tooltip-desc">
                        <span *ngIf="!item.notificationInfoObj.waitlist">We can add you to the waitlist and notify you when item is back in stock.</span>
                        <span *ngIf="item.notificationInfoObj.waitlist">You have been added to the waitlist for this item.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <a *ngIf="item.added && item.inventory.availableToSell>0" (click)="!topService.loading?editItemQty():''" class="item-edit-qty popup"  [class._delete-spinner]="deleteSpinner">
                <span *ngIf="loading || deleteSpinner" class="cart-loader"></span>
                <span *ngIf="!loading && !deleteSpinner" class="_edit-label">Edit QTY</span>
                <span *ngIf="loading && !deleteSpinner">Updating...</span>
                <span *ngIf="deleteSpinner">Deleting...</span>
              </a>

              <a *ngIf="item.added && item.inventory.availableToSell>0 && !deleteSpinner" class="item-remove-from-cart" (click)="removeItemFromCart($event)">
                <span class="item-remove-from-cart-tooltip">Remove</span>
                <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path d="m18 7.21-1.21-1.21-4.79 4.79-4.79-4.79-1.21 1.21 4.79 4.79-4.79 4.79 1.21 1.21 4.79-4.79 4.79 4.79 1.21-1.21-4.79-4.79z" />
                </svg>
              </a>
            </div>
          </div>

          <div class="add-to-controls" *ngIf="userService.isGuest">
            <div>
              <input type="number" appOnlyDigits  value="0" />
            </div>
            <div class="add-to-cart">
              <a class="item-to-cart" (click)="addToCartGuest()">
                <svg  width="28px" height="28px" viewBox="0 0 28 23" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="add-to-cart" fill-rule="nonzero">
                      <path d="M21,4 L20.349795,6.13178125 L18.8204409,6.13178125 L15.8016246,16.16 L4.21811963,16.16 L0,6.05183945 L15.1377483,6.05183945 L12.7901031,13.9780153 L14.3265479,13.9780153 L17.3285301,4 L21,4 Z M12.0799764,17.44 C11.3977189,17.44 10.8446823,18.0130512 10.8446823,18.72 C10.8446823,19.4269488 11.3977189,20 12.0799764,20 C12.762234,20 13.3152705,19.4269488 13.3152705,18.72 C13.3152705,18.0130512 12.762188,17.44 12.0799764,17.44 Z M7.75629904,17.44 C7.07406685,17.44 6.52105086,18.0130512 6.52105086,18.72 C6.52105086,19.4269488 7.07406685,20 7.75629904,20 C8.43857718,20 8.9916391,19.4269488 8.9916391,18.72 C8.9916391,18.0130512 8.43857718,17.44 7.75629904,17.44 Z"
                        id="shopping-cart-icon" transform="translate(10.500000, 12.000000) scale(-1, 1) translate(-10.500000, -12.000000) "></path>
                    </g>
                  </g>
                </svg>
                <span *ngIf="item.loading" class="cart-loader"></span>
                <span>Add to Cart</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <button class="close-modal" type="button" (click)="closeModal(true)"></button>
  </div>


</div>

