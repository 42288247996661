import { Component, OnInit, Input, EventEmitter, Output, HostListener, OnDestroy } from '@angular/core';
import * as models from '../../buyer/interfaces/model';
import { SharedSource } from '../../core/shared-source';
import { IBuyerErrorModal, IItemAttribute } from '../../buyer/interfaces/model';
import { GuestService } from '../../shared/services/guest.service';
import { TopService } from '../services/top.service';
import { AuthService } from '../../auth/auth.service';
import { CartService } from '../../buyer/cart/cart.service';
import { SharedService } from '../shared.service';
import { GridViewItemComponent } from '../../buyer/catalog/grid-view-item/grid-view-item.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { OfferStatusEnum } from './../../buyer/enums/offerStatusEnum';
import { CurrentDealPipe } from '../pipes/current-deal.pipe';
import { CustomCurrencyPipe } from '../pipes/currency.pipe';
import { OnlyDigitsDirective } from '../directives/only-digits.directive';
import { MakeOfferComponent } from './make-offer/make-offer.component';
import { FormsModule } from '@angular/forms';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { NgIf, NgFor, NgClass, NgStyle } from '@angular/common';
import { environment } from './../../../environments/environment';

@Component({
    selector: 'app-item-detail-desktop',
    templateUrl: './item-detail-desktop.component.html',
    styleUrls: ['./item-detail-desktop.component.scss'],
    standalone: true,
    imports: [NgIf, NgClickOutsideDirective, NgFor, NgClass, NgStyle, FormsModule, MakeOfferComponent, OnlyDigitsDirective, CustomCurrencyPipe, CurrentDealPipe]
})
export class ItemDetailDesktopComponent extends GridViewItemComponent implements OnInit, OnDestroy {
  @Input() item: models.IItem;
  @Input() openItemDetails: boolean;
  @Output() onModalClose = new EventEmitter<null>();
  @Output() gradingGuideEmitter = new EventEmitter<models.IItem>();
  @Output() updateQtyOfCartItem = new EventEmitter<models.IItem>();
  searchSubject: BehaviorSubject<models.IItem> = new BehaviorSubject(this.item);
  subscription: Subscription;
  OfferStatusEnum = OfferStatusEnum;
  imageBaseUrl = environment.imageBaseUrl;
  showItemMOQ = false;
  showItemMXQ = false;
  showInputMXQ = false;

  ngOnInit() {
    super.ngOnInit();
    this.subscription = this.searchSubject.pipe(
      debounceTime(500)
    ).subscribe(item => {
        if ( !item || !item.qty ) { return; }
        this.updateItemPriceOverride(item);
      }
    );
  }
  @HostListener('document:keydown.escape', ['$event']) onEscape(event: KeyboardEvent) {
    this.closeModal();
  }

  closeModal(): void {
    this.animateOut = true;
    setTimeout(() => {
      this.onModalClose.emit();
      this.pubSubService.sharedSubject.next({ name: SharedSource.selectedItemChanged, data: false });
      this.animateOut = false;
    }, 500);
  }

  onClickedOutside(e: Event): void {
    if ( this.itemsService.showExwPopUp || this.offerService.showSubmitOfferConfirmationPopup || this.offerService.showOfferInformationPopup) { return; }
    e.preventDefault();
    if (this.itemListErrorModal.isShown === false && this.sharedService.isGradingGuideOpen === false) {
      this.closeModal();
    }
  }

  getVisibleAttributes(): IItemAttribute[] {
    const restrictions = this.item.attributes.filter(attr => attr.label === 'Restrictions')[0];
    this.item.attributes.push(this.item.attributes.splice(this.item.attributes.indexOf(restrictions), 1)[0]);
    return this.item.attributes.filter(a => !a.hide);
  }

   /**
 * @param  {models.IItem} item
 * @returns void
 */
   updateItemPriceOverride(item: models.IItem): void {
    // this.searchSubject.next(item);
    if (!item.qty) { return; }
    super.updateItemPrice(item);

    if (this.router.url.match(/cart$/)) {
      this.updateQtyOfCartItem.emit(item);
    }
  }

  copyText(val: string): void {
    event.preventDefault();
    event.stopPropagation();
    this.utilService.copyTextToClipboard(val);
    this.copied = 'Copied!';
  }

  addToCartBuyer(ignoreExw?: boolean): void {
    if (this.router.url.match(/cart$/)) {
      this.closeModal();
      return;
    }
    this.addToCart(ignoreExw);
  }

  addToWaitlist(e:Event):void {
    if(this.topService.loading){return;}
    this.submitNotification(e);
    this.closeModal();
  }

  checkMoreThanOnHand(): boolean {
    return this.sessionService.isBuyer && this.item.inventory.onHand >= 300;
  }

  isAvailableShown(): boolean {
    if (this.item.inventory.availableToSell === 0 && this.item.inventory.enRoute === 0 && this.item.inventory.onHand === 0) {
      return true;
    }
    return this.item.inventory.onHand && (!this.item.inventory.enRoute || this.item.inventory.onHand >= 300);
  }

  addToCartGuest(): void {
    this.closeModal();
    this.userService.guestPop();
  }

  openGradingGuide(event: any) {
    event.preventDefault();
    this.gradingGuideEmitter.emit(this.item);
    event.stopPropagation();
  }

  openNotificationTooltip(event: any): void {
    event.preventDefault();
    this.topService.notificationTooltipHovered = true;
    const item = this.item;
    this.notificationTooltipEmitter.emit({item, event});
    event.stopPropagation();
  }

  showNotificationInOffer(data) {
    this.notificationTooltipEmitter.emit(data);
  }

  itemMOQTooltip(): void {
    this.showItemMOQ = !this.showItemMOQ;
  }

  itemMXQTooltip(v): void {
     switch (v) {
       case 1: this.showItemMXQ = !this.showItemMXQ; break;
       case 2: this.showInputMXQ = !this.showInputMXQ; break;
     }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }    
  }

}
