<ng-template #loading>
  <div class="loader loading-outlets" [class.mobile]="topService.isMobile"></div>
</ng-template>

<div class="outlets-wrapper" [class.mobile]="topService.isMobile">
  <div class="outlets-page">
    <div class="outlets-page__top">
      <img src="../../../assets/images/outlets/introducing-text-page.svg" class="visible-desktop" width="462"
        height="132" alt="">
      <img src="../../../assets/images/outlets/introducing-text-page-mobile.svg" class="visible-mobile" width="260"
        height="204" alt="">
    </div>
    <div class="outlets-page__filters">
      <div class="outlets-page__filters-top">
        <div class="filter-sort visible-desktop" *ngIf="itemsService?.manufacturersData && !topService.isMobile">
          <app-dropdown *ngIf="itemsService?.manufacturersData && !topService.isMobile"
            [items]="manufacturersDataSorted" [fieldToShow]="'name'" [fieldToSave]="'name'" [isFromBuyerPortal]="true"
            [(ngModel)]="selectedManufName" [defaultTitle]="getDefaultTitleForManufacturer()"
            (onSelect)="manufacturersData($event)" darkTheme="true">
          </app-dropdown>

        </div>
        <div class="filter-sort visible-mobile" *ngIf="itemsService?.manufacturersData">
          <select [(ngModel)]="selectedManu" (change)="manufacturersData(selectedManu)" class="filter-select"
            *ngIf="topService.isMobile">
            <option selected>All Manufacturers</option>
            <option *ngFor="let manu of manufacturersDataSorted" [ngValue]="manu"
              [class.hide]="manu.name==='All Manufacturers'">
              {{ manu.name }}
            </option>
          </select>
        </div>
        <div class="filter-view">
          <span class="filter-view-item" title="Card View" (click)="toggleView(true)" [class._active]="isCardView">
            <img src="../../../assets/images/outlets/cards.svg" width="24" height="24" />
          </span>
          <span class="filter-view-item" title="List View" (click)="toggleView(false)" [class._active]="!isCardView">
            <img src="../../../assets/images/outlets/list.svg" width="24" height="24" />
          </span>
        </div>
      </div>
      <div class="outlets-page__filters-btm">
        <span class="filter-az__item _all visible-mobile" (click)="filterByCharacter('All')"
          [class._active]="'All' === filterCurrentLetter">All</span>
        <div class="filter-az">
          <span class="filter-az__item _active" *ngFor="let character of alphabet"
            (click)="filterByCharacter(character)" [class._active]="character === filterCurrentLetter">{{ character
            }}</span>
        </div>
      </div>
    </div>
    <ul class="outlets-page-list" *ngIf="itemFilterService.allReactiveManufacturers as manufacturers; else loading"
      [ngClass]="{'_card-view': isCardView, '_list-view': !isCardView}">
      <ng-container>
        <li
          *ngFor="let manufacturer of manufacturers | manufacturersFilter:filterCurrentLetter | manufacturerSortby:sortBy">
          <a (click)="manufacturersData(manufacturer)" class="outlets-page-list-item">
            <span class="outlets-page-list-item__icon">
              <img [src]="imageBaseUrl + manufacturer.logoUrl" alt="" *ngIf="manufacturer.logoUrl">
            </span>
            <span class="outlets-page-list-item__title">{{ manufacturer.name }} Outlet</span>
            <span class="outlets-page-list-item__count">{{ manufacturer.count }}</span>
          </a>
        </li>
      </ng-container>
    </ul>
    <!-- empty state -->
    <div class="outlets-not-found" *ngIf="manufacturersReady && !outletsService.currentManufacturers?.length">
      <h4 class="outlets-not-found__txt">No Outlets found</h4>
      <a class="outlets-not-found__link" (click)="reload()">Refresh</a>
    </div>
  </div>
</div>

<app-bottom-nav-mobile *ngIf="topService.isMobile"></app-bottom-nav-mobile>