<!-- SELLER -->
<form  [formGroup]="fg" *ngIf="fg">
  <div class="item-wrapper" [class.item-detailed-modal]="isPopup"
    [class.disableddiv]="item.disabled"
    [class._archived]="fg.controls.status_skuStatus.value === listingSkuStatusNamesEnum.archived"
    [class._reduse]="item.reactivationThreshold && item.status?.skuStatus === listingSkuStatusNamesEnum.removed"
    [class._select-active]="item.reactivationThreshold && this.vendorService?.search?.filters?.skuStatus === this.listingSkuStatusValuesEnum.removed && !isPopup"
    [ngClass]="{'error-border': item.errors && item.errors.errors && item.showError && !isPopup}">
    <div *ngIf="loader" class="loader"></div>

    <div class="item-header"  *ngIf="isPopup">
      <div class="close" role="button" (click)="closePopUp()"></div>
    </div>
    
    <div class="item-select" *ngIf="item.reactivationThreshold && this.vendorService?.search?.filters?.skuStatus === this.listingSkuStatusValuesEnum.removed && !isPopup">
      <label class="item-checkbox">
        <input type="checkbox" formControlName="reactivationCheckBox" (change)="addReactivationCount()">
        <i class="_icon"></i>
      </label>
    </div>

    <div class="item-top-info">

      <!-- Logo -->
      <div class="item-logo-number" *ngIf="!isPopup">
        <div class="manufacturer-logo">
          <img [src]="imageBaseUrl + getManufacturerLink()" width="50" height="50"/>
        </div>
      </div>

      <!-- status -->
      <div class="item-status">
        <div class="item-info-title">Status</div>
          <app-dropdown formControlName="status_skuStatus"  [showCostPopup]="verifyToShowCostPopup()" [muteClick]="internalStatuses.length===1"
            *ngIf="internalStatuses" [items]="internalStatuses" [fieldToShow]="'text'" [fieldToSave]="'text'" [showInRed]="isRemovedSelected()" >
          </app-dropdown>
      </div>

      <!-- part number -->
      <div class="item-part-number">
        <div class="item-info-title"> Part Number </div>
        <div [tooltip]="isAll ? sellerName : ''"
          (click)="copyText(item.vendorItemCode)">
          <input type="text" class="form-control disableMe"
            formControlName="vendorItemCode">
        </div>

        <span *ngIf="vendorService.isAdmin || vendorService.isVendorManager">
          <div class="item-info-title">
            XID
          </div>
          <div [tooltip]="isAll ? sellerName : ''"
            (click)="copyText(item.hubxItemCode)">
            <input
            type="text"
            class="form-control disableMe"
            formControlName="hubxItemCode">
          </div>
        </span>

      </div>

      <!-- description -->
      <div class="item-description">
        <div class="item-info-title">Description</div>
        <textarea
        [class.disableMe]="!vendorService.isAdmin && !vendorService.isVendorManager"
        [attr.disabled]="item.disabled ? '' : null"
        class="form-control"
        formControlName="description"></textarea>
      </div>

      <!-- promo -->
      <div class="item-promo">
        <div class="item-info-title">Promo</div>
        <div class="item-promo-group">
          <a role="button" class="item-icon-btn __no-events" [class.disabled]="!isPromoActive(item,'Today\'s Deals')">
            <img
              [src]="isPromoActive(item,'Today\'s Deals')?  'assets/images/vendor-icons/today-deal-active.svg' : 'assets/images/vendor-icons/today-deal-inactive.svg'" />
          </a>
          <a role="button" class="item-icon-btn __no-events" [class.disabled]="!isPromoActive(item,'Price Drop')">
            <img
              [src]="isPromoActive(item,'Price Drop')?  'assets/images/vendor-icons/price-drop-active.svg' : 'assets/images/vendor-icons/price-drop-inactive.svg'" />
          </a>
          <a role="button" class="item-icon-btn __no-events" [class.disabled]="!isPromoActive(item,'Just Launched')">
            <img
              [src]="isPromoActive(item,'Just Launched') ? 'assets/images/vendor-icons/just-launched-active.svg' : 'assets/images/vendor-icons/just-launched-inactive.svg'" />
          </a>
          <a role="button" class="item-icon-btn __no-events" [class.disabled]="!isPromoActive(item,'New')">
            <img
              [src]="isPromoActive(item,'New') ?   'assets/images/vendor-icons/new-active.svg' :  'assets/images/vendor-icons/new-inactive.svg'" />
          </a>
        </div>
      </div>

      <div class="item-availability">
        <!-- available -->
        <div class="item-info-title">Available</div>
        <div>
          <input appOnlyDigits type="number" class="form-control"
            formControlName="inventory_availableToSell">
        </div>

        <!-- eta -->
        <span *ngIf="vendorService.isAdmin || vendorService.isVendorManager">
          <div class="item-info-title">ETA</div>
          <div>
            <input appOnlyDigits type="number"
            class="form-control"
            formControlName="eta">
          </div>
        </span>
      </div>

      <!-- discounts -->
      <div class="item-dq" *ngFor="let price of formPrices; let i = index"
      formArrayName="prices">
        <div class="item-info-title"
        [tooltip]="'Discount Quantity'"
        containerClass="moqdq" placement="top">
          DQ #{{i+1}}
        </div>
        <div class="input-group-vertical" [formGroupName]="i" [class.error]="fg.invalid && fg.errors.controlName === i &&
        [errorCodesEnum.invalidDiscount, errorCodesEnum.invalidDiscountVsPrice].includes(fg.errors.errorCode)">
          <div class="input-qty">
            <input
            type="text"
            class="form-control"
            [class.disableMe]="vendorService.isSeller"
            [formControl]="pricesControlsArray[i].controls.fromQty"
            (blur)="checkPrefix(qtyInput)"
            prefix='<' mask="0*" #maskElem #qtyInput>
          </div>
          <div class="input-price disc"
            [class.not-empty]="price.unitPrice !== null">
            <input
            type="text"
            class="form-control"
            [formControl]="pricesControlsArray[i].controls.unitPrice"
            (blur)="checkPrefix(unitPrice)"
            [class.disableMe]="vendorService.isSeller"
            prefix='$' mask="separator.2" thousandSeparator="," #maskElem #unitPrice>
          </div>
        </div>
      </div>

      <div class="item-moq-group">

        <!-- moq -->
        <div class="item-info-title" [tooltip]="'Minimum Order Quantity'" containerClass="moqdq" placement="top">
          MOQ
        </div>

        <div class="input-qty">
          <input
          type="text"
          class="form-control"
          [class.disableMe]="vendorService.isSeller"
          [class.error]="fg.invalid && fg.errors.errorCode === errorCodesEnum.invalidMinQty"
          [formControl]="fg.controls.moq" mask="10*" [validation]="false" [patterns]="customPatterns">
        </div>

        <!-- MXQ -->
        <div class="item-info-title"
        *ngIf="vendorService.isAdmin || vendorService.isSeller || vendorService.isVendorManager"
        [tooltip]="'Maximum Order Quantity'"
        containerClass="moqdq" placement="top">
          MXQ
        </div>
        <div class="input-qty">
          <input type="text" class="form-control"
          [class.error]="fg.invalid && fg.errors.errorCode === errorCodesEnum.invalidMinQty"
          [formControl]="fg.controls.mxq" mask="10*" [validation]="false" [patterns]="customPatterns">
        </div>

      </div>

      <!-- Cost -->
      <div class="item-price __cost" [class.red-border]="item.reactivationThreshold && item.status?.skuStatus === listingSkuStatusNamesEnum.removed && fg.controls.cost.value > item.reactivationThreshold">
        <div class="item-info-title">
          Cost
        </div>
        <div class="input-price" [class.not-empty]="item.cost !== null">
          <input
            type="text"
            class="form-control"
            [formControl]="fg.controls.cost"
            [class.disableMe]="isFieldDisabled()"
            prefix='$' mask="separator.2" thousandSeparator=",">

            <div class="reduse-cost-tooltip" *ngIf="item.reactivationThreshold && item.status?.skuStatus === listingSkuStatusNamesEnum.removed && reduceTooltipShow" [class.for-seller]="vendorService.isSeller">

                <div class="reduse-cost-body">
                  <div class="reduse-cost-header">
                    <h3>
                      0 items were sold within a week
                    </h3>
                    
                  </div>
                  <div class="reduse-cost-content">
                    <p>
                      To activate listing, <br> you must reduce cost <br> by at least {{getReductionRuleDisplayValue()}}
                    </p>
                    <div class="reduse-cost-content__ctrl">
                      <button type="button" class="btn" (click)="applyReactivationThreshold()">
                        <span> Reduce to {{item.reactivationThreshold | CustomCurrency}}</span>
                      </button>
                    </div>
                  </div>
                  <div class="reduse-cost-footer">
                    <span>
                      The above cost is the minimum requirement to activate the status
                    </span>
                  </div>
                </div>
              </div>
        </div>
      </div>

      <!-- Price -->
      <div class="item-price" *ngIf="vendorService.isAdmin || vendorService.isVendorManager">
        <div class="item-info-title">
          Price
        </div>
        <div class="input-price" [ngClass]="{'not-empty': item.unitPrice !== null}">
              <input
              type="text"
              class="form-control"
              [formControl]="fg.controls.unitPrice"
              prefix='$' mask="separator.2" thousandSeparator=","
              [ngClass]="{'disableMe': isFieldDisabled()}"
              [class.error]="fg.invalid && fg.errors.errorCode === errorCodesEnum.invalidDiscountVsPrice">
          </div>
        </div>
    </div>

    <div class="item-bottom-info">
      <!-- launched -->
      <div class="launched">
        <span> {{ item.lastActivatedOn ? 'Launched' : '' }}</span>
        <span>{{ item.lastActivatedOn ? ( item.lastActivatedOn | date:'M/d/yy, h:mm' ) : '' }}</span>
      </div>
      <div class="category-select">
      </div>

      <!-- attrs -->
      <div class="item-attributes">
          <select
          class="item-bottom-select input-group-condition"
          formControlName="condition"
          [ngClass]="{'disabled-div': vendorService.isSeller}">
            <option *ngFor="let cond of condition.values" value="{{cond.code}}">{{cond.value}}</option>
          </select>

          <select
          class="item-bottom-select input-group-warranty"
          formControlName="warranty"
          [ngClass]="{'disabled-div': vendorService.isSeller}">
            <option *ngFor="let wart of warranty.values" value="{{wart.code}}">{{wart.value}}</option>
          </select>

          <select
          class="item-bottom-select input-group-packaging"
          formControlName="packaging"
          [ngClass]="{'disabled-div': vendorService.isSeller}">
            <option *ngFor="let pckg of packaging.values" value="{{pckg.code}}">{{pckg.value}}</option>
          </select>


          <div class="restrictions-select" [class.edit-visible]="fg.controls.restrictions.value">
            <select class="item-bottom-select input-group-restrictions disableMe"
            formControlName="restrictions"
            [ngClass]="{'disabled-div': vendorService.isSeller}">
              <!-- this is to show 'No' when there's nothing selected -->
              <option [value]="null" style="display: none;">No</option>
              <option *ngFor="let restr of restrictions.values" [value]="restr.code">{{restr.value}}</option>
            </select>
            <div class="restrictions-select-right" *ngIf="fg.controls.restrictionDescription.value">
              <div class="restrictions-select-right__txt">
                {{ fg.controls.restrictionDescription.value }}
                <span class="_shadow"></span>
              </div>
              <span class="restrictions-select-right__btn" (click)="openRestrictionModal()">Edit</span>
              <div class="restrictions-select-tooltip">
                {{ fg.controls.restrictionDescription.value }}
              </div>
            </div>
          </div>

          <select class="item-bottom-select input-group-exw disableMe"
          formControlName="exwPointValue"
          [ngClass]="{'disabled-div': vendorService.isSeller}">
            <option *ngFor="let exw of exwOptions" [value]="exw.value">{{exw.text}}</option>
          </select>

      </div>

      <div class="bottom-icons">
        <div class="bottom-icons-group">
          <a role="button" class="item-icon-btn" (click)="showCommentModal()">
            <img src="assets/images/vendor-icons/info.svg" />
          </a>
        </div>
      </div>
      <div class="updated" [ngClass]="{'updatedNow':item.updatedNow}">
        <span> {{ item.lastUpdatedOn ? 'Updated' : '' }}</span>
        <span> {{ item.lastUpdatedOn ? ( item.lastUpdatedOn | date:'M/d/yy, h:mm' ) : '' }}</span>
      </div>
      <div class="control-btns">

        <a role="button" class="item-icon-btn has-item-btn-tooltip"
          [ngClass]="{'vendor-item-updated': vendorService.updatedMappedItems.has(item.id)}"
          (click)="item.disabled ? return : undoItemUpdates($event)" placement="bottom">
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="m6.00421756 12c0 3.3145476 2.685161 6 5.99819244 6 3.3130315 0 5.9981925-2.6854524 5.9981925-6 0-2.93735818-2.1307842-5.42920384-4.9978674-5.90946034l-.3577692-.05992884v-3.01214526l.4814055.05980358c4.4775011.55622674 7.8736286 4.37074396 7.8736286 8.92173086 0 4.9716573-4.0279056 9-8.99759 9-4.96968431 0-8.99758993-4.0283427-8.99758993-9 0-2.08356377.71259734-4.052563 1.99325746-5.63828975l-1.39602921-1.39460219-.10570299-.10190197c-.06353372-.06205206-.11475278-.11449547-.16190711-.16674877-.21437519-.23755639-.33443822-.46158458-.33443822-.73417161 0-.53166627.43160298-.96428571.96413509-.96428571h5.35563658c.14451306 0 .28211456.01920102.44139649.09157803.32099494.14585869.52273864.45091876.52273864.87270768v5.35714286c0 .53154704-.43202033.96428573-.96413513.96428573-.32186896 0-.51778503-.1144239-.7985355-.39298086l-.23290517-.23937411-1.14870224-1.14942643c-.73244391 1.01204442-1.1354112 2.2226953-1.1354112 3.4960671z" fill="#191a19"/></svg>
          <div class="item-btn-tooltip">Undo</div>
        </a>
        <a role="button" class="item-icon-btn update has-item-btn-tooltip" (click)="fg.invalid ? return : updateSingleItem(item)"
          [ngClass]="{'vendor-item-updated': (vendorService.updatedMappedItems.has(item.id) && !fg.invalid)}">
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
            <path fill="#191a19" d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" />
          </svg>
          <div class="item-btn-tooltip">Save</div>
        </a>

        <div class="control-btns-tooltip">
          <div class="__launched">
            <span> {{ item.lastActivatedOn ? 'Launched' : '' }}</span>
            <span>{{ item.lastActivatedOn ? ( item.lastActivatedOn | date:'M/d/yy, h:mm' ) : '' }}</span>
          </div>
          <div class="__updated" [ngClass]="{'updatedNow':item.updatedNow}">
            <span> {{ item.lastUpdatedOn ? 'Updated' : '' }}</span>
            <span> {{ item.lastUpdatedOn ? ( item.lastUpdatedOn | date:'M/d/yy, h:mm' ) : '' }}</span>
          </div>
        </div>

        <div class="item-archive-controls">
          <a role="button" class="item-archive-controls__btn restore has-item-btn-tooltip"
          *ngIf="fg.controls.status_skuStatus.value === listingSkuStatusNamesEnum.archived"
          (mouseenter)="tooltipMsg = 'Archived'" (click)="item.disabled ? return : restoreItem()">Restore</a>
          <a role="button" class="item-archive-controls__btn archive has-item-btn-tooltip"
          *ngIf="fg.controls.status_skuStatus.value !== listingSkuStatusNamesEnum.archived"
          (mouseenter)="tooltipMsg = 'Archive'" (click)="item.disabled ? return : archiveItem()">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g fill="#EB3B44" fill-rule="nonzero">
                  <polygon points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"></polygon>
                </g>
              </g>
            </svg>
          </a>
          <div class="item-btn-tooltip">{{tooltipMsg}}</div>
        </div>
      </div>
    </div>

    <div class="center-block">

      <div *ngIf="item.reactivationThreshold && item.status?.skuStatus === listingSkuStatusNamesEnum.removed" class="__reduse-cost">
        <img src="/assets/images/alert.svg" width="24px" height="24px" />
        <span> In order to reactivate, reduce cost by {{getReductionRuleDisplayValue()}}</span>
      </div>
      <!-- Backend errors -->
      <div class="line-error col-md-12" *ngIf="showBackendError">
        <img [src]="imageBaseUrl + '/assets/images/exclamation-mark.svg'" width="24px" height="24px" />
        {{'Error: ' + item.errors.errors[0].errorMessage }}
        <br>
      </div>

      <!-- form validation errors -->
      <div class="line-error col-md-12" *ngIf="fg.invalid && !showBackendError">
        <img [src]="imageBaseUrl + '/assets/images/exclamation-mark.svg'" width="24px" height="24px" />
        <span>{{ fg.errors.errorMsg }}</span>
        <br>
      </div>

    </div>
  </div>

</form>

<div *ngIf="showRestrictionModal" [class._inside-popup]="isPopup">
  <app-item-restriction [fg]="fg" [currentRestriction]="item.mappedAttributes.restrictions.description" (closeModal)="closeRestrictionModal()"></app-item-restriction>
</div>
